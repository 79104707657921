<template>
  <div>
        <Form
        ref="formCampaignConfig"
        label-position="right"
        :label-width="200"
        :rules="validationRules"
        :model="config"
        id="form-campaign-config"
    >
        <div v-show="showConfigForm">
            <Divider orientation="left">Accounts and Assets</Divider>
            <Form-Item label="Facebook Account" prop="bpseId">
                <Row>
                <Row-Col v-if="!isPromotionFlow" span="18">
                    <Select
                    :disabled="isExistingConfig || isReadOnlyMode"
                    placeholder="Select an account"
                    v-model="config.bpseId"
                    :transfer="true"
                    filterable
                    >
                    <Option
                        v-for="(item, index) in accounts"
                        :key="index"
                        :value="item.Id"
                        :disabled="item.IsOauthExpired || item.TestMode"
                    >{{ item.AccountId + " - " + item.NickName }}</Option>
                    </Select>
                </Row-Col>
                <Row-Col v-else span="18">
                    <Select v-model="config.bpseId" :transfer="true" disabled>
                    <Option :value="config.bpseId">{{ config.facebookAccountId }}</Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item id="alert-msg" v-if="fetchingPageInfoFailed">
            <Row>
              <Row-Col span="18">
                <Alert type="error" closable @on-close="fetchingPageInfoFailed=false">
                Failed to fetch selected page information.
                </Alert>
              </Row-Col>
            </Row>
            </Form-Item>
            <Form-Item label="Facebook Page" prop="facebookPageId">
                <Row>
                <Row-Col v-if="!isPromotionFlow" span="18">
                    <Select
                    placeholder="Select a page"
                    :disabled="config.bpseId === '' || isExistingConfig || isReadOnlyMode"
                    v-model="config.facebookPageId"
                    not-found-text="No results"
                    :transfer="true"
                    filterable
                    >
                    <Option
                        v-for="(item, index) in pages"
                        :key="index"
                        :value="item.Id"
                    >{{ item.Name }} ({{ item.Id }})</Option>
                    </Select>
                </Row-Col>
                <Row-Col v-else span="18">
                    <Select v-model="config.facebookPageId" :transfer="true" disabled>
                    <Option :value="config.facebookPageId">{{ config.facebookPageId }}</Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Instagram Account" prop="instagramAccountId">
                <Row>
                <Row-Col v-if="!isPromotionFlow" span="18">
                    <Select
                    placeholder="Select an instagram account"
                    :disabled="config.bpseId === '' || isReadOnlyMode"
                    v-model="config.instagramAccountId"
                    not-found-text="No results"
                    :transfer="true"
                    filterable
                    clearable
                    >
                    <Option
                        v-for="(item, index) in instagramAccounts"
                        :key="index"
                        :value="item.Id"
                    >{{ item.Name }}</Option>
                    </Select>
                </Row-Col>
                <Row-Col v-else span="18">
                    <Select v-model="config.instagramAccountId" :transfer="true" disabled>
                    <Option :value="config.instagramAccountId || ''">
                        {{ config.instagramAccountId }}
                    </Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            <Divider orientation="left">Campaign and Adgroups</Divider>
            <Form-Item label="Buy Type" prop="campaign.buyType" v-show="!isPromotionFlow">
              <Row>
                <Row-Col span="18">
                    <Select
                    :disabled="(isExistingConfig && !inPromoDashboard) || isReadOnlyMode"
                    placeholder="Buying Type"
                    v-model="config.campaign.buyType"
                    not-found-text="No results"
                    >
                    <Option
                        v-for="(item, index) in buyingTypes"
                        :key="index"
                        :value="item.id"
                    >{{ item.name }}</Option>
                    </Select>
                </Row-Col>
              </Row>
            </Form-Item>
            <Form-Item label="Campaign Objective" prop="campaign.objective" v-show="!isPromotionFlow || isWizardFlow">
                <Row>
                <Row-Col span="18">
                    <Select
                    :disabled="isExistingConfig || isReadOnlyMode"
                    placeholder="Select an objective"
                    v-model="config.campaign.objective"
                    not-found-text="No results"
                    >
                    <Option
                        v-for="(id, name) in computedFacebookObjectives"
                        :key="id"
                        :value="id"
                    >{{ name }}</Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item
              label="Advantage+ Shopping Campaigns"
              prop="campaign.isAdvantagePlusShoppingCampaign"
              key="campaign.isAdvantagePlusShoppingCampaign"
              v-show="(!isPromotionFlow || !isWizardFlow) && isDPAObjective(config, true)"
            >
                <Row>
                <Row-Col span="18">
                    <i-switch
                      :disabled="isExistingConfig || isReadOnlyMode"
                      size="small"
                      v-model="config.campaign.isAdvantagePlusShoppingCampaign">
                    </i-switch>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item
              v-if="isAppInstallsObjective(config)"
              prop="campaign.isAutomatedAppAdsEnabled"
              key="campaign.isAutomatedAppAdsEnabled"
            >
              <Row>
                <Row-Col span="18">
                  <Checkbox v-model="config.campaign.isAutomatedAppAdsEnabled" :disabled="isExistingConfig || isReadOnlyMode">
                    <span>Automated App Ads</span>
                  </Checkbox>
                </Row-Col>
              </Row>
            </Form-Item>
            <template v-if="!isPromotionFlow || isWizardFlow">
                <Form-Item
                v-show="showDestination"
                label="Destination"
                prop="adset.destination"
                >
                <Row>
                    <Row-Col span="18">
                    <Select
                        placeholder="Select a destination"
                        v-model="config.adset.destination"
                        not-found-text="No results"
                        :disabled="!config.facebookPageId || disableDestination || isReadOnlyMode"
                    >
                        <Option
                        v-for="(dest, index) in destinations"
                        :key="index"
                        :value="dest.id"
                        >{{ dest.name }}</Option>
                    </Select>
                    </Row-Col>
                </Row>
                  <Row v-if="config.adset.destination == fbEnums.destinationType.App">
                       <RadioGroup v-model="setPageActorOverride">
                        <Radio label="useApp"><span> Use the app as the ad sponsor and destination when people click on your profile</span></Radio>
                        <Radio label="usePage"><span> Use the page as the ad sponsor and destination when people click on your profile</span></Radio>
                    </RadioGroup>
                </Row>
                </Form-Item>
            </template>

            <template v-if="this.isLeadGenerationObjective(config) && isWizardFlow">
              <Divider orientation="left"> Lead Form </Divider>
              <Form-Item label="Lead Form" prop="storeLeadFormId">
                <Row>
                  <Row-Col span="18">
                    <Select
                      v-model="config.storeLeadFormId"
                      placeholder="Select a Lead Form"
                      :transfer="true"
                      filterable
                      :disabled="isReadOnlyMode"
                      clearable
                    >
                      <Option
                        v-for="leadForm in leadForms"
                        :key="leadForm.id"
                        :value="leadForm.id"
                        >{{ leadForm.name }}</Option
                      >
                    </Select>
                  </Row-Col>
                </Row>
              </Form-Item>
            </template>

            <Row v-show="showMobileAppAlert" v-if='isDPAObjective(config)' class="no-app-info-wrapper">
                <div :class="{'ivu-form-item-error-tip': showMobileAppAlert}"></div>
                <div class="no-app-info">
                <i class="fas fa-exclamation-triangle no-app-icon"></i>
                <div class="no-app-info-msg">
                    <span class="no-app-info-bold">
                    There are no apps linked to this account
                    </span>
                    <span class="no-app-info-text">
                    You can link apps to your account
                    <a
                        class="no-app-info-link"
                        :href="'/App/BizProfiles/Channels/' + businessProfileId"
                        target="_blank"
                    >
                        here </a
                    >
                    </span>
                </div>
                </div>
            </Row>

            <Form-Item label="Creation" prop="campaign.grouping" v-show="!isPromotionFlow">
                <Row>
                <Row-Col span="18">
                    <Select
                    :disabled="isExistingConfig || isReadOnlyMode"
                    placeholder="Select campaign grouping"
                    v-model="config.campaign.grouping"
                    not-found-text="No results"
                    >
                    <Option
                        v-for="(item, index) in campaignGroupings"
                        :key="index"
                        :value="item.id"
                    >{{ item.name }}</Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Campaign Name" prop="campaign.name" key="campaign.name">
                <Row>
                <Row-Col span="18">
                    <i-input v-model="config.campaign.name" placeholder="Name" :disabled="isNomenclatureEnabled || isReadOnlyMode" />
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Campaign Status" prop="campaign.status" key="campaign.status">
                <Row>
                <Row-Col>
                    <RadioGroup v-model="config.campaign.status">
                        <Radio label="1" :disabled="isReadOnlyMode"><span>Active</span></Radio>
                        <Radio label="2" :disabled="isReadOnlyMode"><span>Pause</span></Radio>
                    </RadioGroup>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Auto launch on requirement approval" prop="requirementsAutoLaunch" key="config.requirementsAutoLaunch" v-show="!isPromotionFlow">
                <Row>
                <Row-Col span="18">
                  <i-switch
                      :disabled="isExistingConfig || isReadOnlyMode"
                      size="small"
                      v-model="config.requirementsAutoLaunch">
                    </i-switch>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Dynamic Ad Voice" prop="ad.dynamicAdVoice" key="ad.dynamicAdVoice" v-show="isStoreVisitsObjective(config)">
                <Row>
                <Row-Col>
                    <RadioGroup v-model="config.ad.dynamicAdVoice">
                        <Radio label="1" :disabled="isReadOnlyMode"><span>Main Page</span></Radio>
                        <Radio label="2" :disabled="isReadOnlyMode"><span>Store Page</span></Radio>
                    </RadioGroup>
                </Row-Col>
                </Row>
            </Form-Item>
            <Row v-show="config.campaign.isAutomatedAppAdsEnabled && !isPromotionFlow" v-if='isAppInstallsObjective(config)' class="no-app-info-wrapper">
              <Row-Col span="18">
                <div
                  class="alert alert-info"
                  role="alert"
                >
                  Choose a product catalog to promote from a product set,
                  or leave it empty to manually choose individual images or videos.
                </div>
              </Row-Col>
            </Row>
          <Form-Item id="alert-msg" v-if="fetchCatalogsApiCallFailed">
            <Row>
              <Row-Col span="18">
                <Alert type="error" closable @on-close="fetchCatalogsApiCallFailed=false">
                Failed to fetch product catalogs.
                </Alert>
              </Row-Col>
            </Row>
          </Form-Item>
            <Form-Item label="Product Catalog" prop="campaign.productCatalog"
                key="campaign.productCatalog"
                v-show="!isPromotionFlow && isCatalogSupportedForConfig(config, true) && !config.campaign.isAdvantagePlusShoppingCampaign">
                <Row>
                <Row-Col span="18">
                    <Select
                    placeholder="Select a Product Catalog"
                    v-model="config.campaign.productCatalog"
                    :disabled="areCatalogsBeingFetched || isReadOnlyMode"
                    filterable
                    clearable
                    >
                    <OptionGroup label="DX Catalogs">
                        <Option
                        v-for="(item, index) in dxProductCatalogs"
                        :key="index"
                        :value="item.Id"
                        >{{ item.Name }} ({{ item.ProductCount }} Products)</Option>
                    </OptionGroup>
                    <OptionGroup label="Live Catalogs">
                        <Option
                        v-for="(item, index) in liveProductCatalogs"
                        :key="index"
                        :value="item.Id"
                        >{{ item.Name }} ({{ item.ProductCount }} Products)</Option>
                    </OptionGroup>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="AdGroup Grouping" v-show="!isPromotionFlow" prop="adset.grouping" key="adset.grouping">
                <Row>
                <Row-Col span="18">
                    <CheckboxGroup v-model="config.adset.grouping">
                    <Checkbox label="Audience and Placement" disabled></Checkbox>
                    <Checkbox label="Platform" :disabled="isExistingConfig || isReadOnlyMode || config.campaign.isAdvantagePlusShoppingCampaign"></Checkbox>
                    <Checkbox label="Ad Schedule" :disabled="isReadOnlyMode || config.campaign.isAdvantagePlusShoppingCampaign"></Checkbox>
                    <!-- <Checkbox label="Unique Creative"></Checkbox> -->
                    </CheckboxGroup>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Adgroup Name" prop="adset.name" key="adset.name">
                <Row>
                <Row-Col span="18">
                    <i-input v-model="config.adset.name" placeholder="Name" :disabled="IsAdgNameDisabledForPromotion || isReadOnlyMode || isNomenclatureEnabledForAdset"/>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Adgroup Status" prop="adset.status" v-show="!isPromotionFlow" key="adset.status">
                <Row>
                <Row-Col>
                    <RadioGroup v-model="config.adset.status">
                        <Radio label="1" :disabled="isReadOnlyMode"><span>Active</span></Radio>
                        <Radio label="2" :disabled="isReadOnlyMode"><span>Pause</span></Radio>
                    </RadioGroup>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Ad Status" prop="ad.status" v-show="!isPromotionFlow" key="ad.status">
                <Row>
                <Row-Col>
                    <RadioGroup v-model="config.ad.status">
                        <Radio label="1" :disabled="isReadOnlyMode"><span>Active</span></Radio>
                        <Radio label="9" :disabled="isReadOnlyMode"><span>Pause</span></Radio>
                    </RadioGroup>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Target Selection" prop="adset.targetSelection" key="adset.targetSelection" v-show="!isRnFConfig && !isPromotionFlow">
                <Row>
                <Row-Col>
                    <RadioGroup v-model="config.adset.targetSelection">
                    <Radio :disabled="isExistingConfig || isReadOnlyMode" label="Individually"></Radio>
                    <Radio :disabled="isExistingConfig || isReadOnlyMode" label="By Label"></Radio>
                    </RadioGroup>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Adgroup per location" prop="campaign.adgroupPerLocation" key="campaign.adgroupPerLocation" v-show="!isPromotionFlow">
                <Row>
                <Row-Col>
                    <RadioGroup v-model="config.campaign.adgroupPerLocation">
                        <Radio :disabled="isExistingConfig || isReadOnlyMode || config.campaign.isAdvantagePlusShoppingCampaign" label="all"><span>1 adgroup for all locations</span></Radio>
                        <Radio :disabled="isExistingConfig || isReadOnlyMode || (isStoreVisitsObjective(config) && config.campaign.locationMode == fbEnums.locationMode.PageSets) || config.campaign.isAdvantagePlusShoppingCampaign" label="one"><span>1 adgroup per location</span></Radio>
                    </RadioGroup>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Beneficiary Name" prop="adset.dsaBeneficiary" key="adset.dsaBeneficiary">
                <Row>
                <Row-Col span="18">
                    <i-input v-model="config.adset.dsaBeneficiary" placeholder="Beneficier Name" :disabled="isReadOnlyMode"/>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item>
              <Row>
                <Row-Col span="18">
                  <Alert>Beneficiary is required if targeting EU countries, Leave blank otherwise.</Alert>
                </Row-Col>
              </Row>
            </Form-Item>
            <Form-Item
              label=""
              prop="adset.isPayorDifferent"
              key="adset.isPayorDifferent"
            >
                <Row>
                <Row-Col span="1">
                    <i-switch
                      size="small"
                      v-model="config.adset.isPayorDifferent">
                    </i-switch>
                </Row-Col>
                <Row-Col span = "17">
                  <span>A different person or organisation is paying</span>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Payer Name" prop="adset.dsaPayor" key="adset.dsaPayor" v-show="config.adset.isPayorDifferent">
                <Row>
                <Row-Col span="18">
                    <i-input v-model="config.adset.dsaPayor" placeholder="Payer Name" :disabled="isReadOnlyMode"/>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Location Mode" prop="campaign.locationMode" key="campaign.locationMode" v-show="!isPromotionFlow">
                <Row>
                <Row-Col>
                    <RadioGroup v-model="config.campaign.locationMode">
                        <Radio :disabled="isExistingConfig || isReadOnlyMode" :label="fbEnums.locationMode.Standard"><span>Standard</span></Radio>
                        <Radio :disabled="isExistingConfig || isReadOnlyMode" :label="fbEnums.locationMode.Hyperlocal"><span>Hyperlocal</span></Radio>
                        <Radio :disabled="isExistingConfig || isReadOnlyMode" v-if="isStoreVisitsObjective(config)" :label="fbEnums.locationMode.PageSets"><span>Page Sets</span></Radio>
                    </RadioGroup>
                </Row-Col>
                </Row>
            </Form-Item>

            <Form-Item label="Store Inclusion Location Types" v-if="isPromotionFlow && config.campaign.locationMode == fbEnums.locationMode.Hyperlocal" prop="adset.inclusionLocationTypes" key="adset.inclusionLocationTypes">
                <Row>
                  <Row-Col span="18">
                    <Select
                      multiple
                      placeholder="Select Location Types"
                      v-model="config.adset.inclusionLocationTypes"
                      :disabled="isExistingConfig || isReadOnlyMode"
                    >
                      <Option
                        v-for="(value, key) in fbEnums.locationTypes" :key="key"
                        :value="value"
                      >{{ key }}</Option>
                    </Select>
                  </Row-Col>
                </Row>
            </Form-Item>

            <Form-Item label="Store Exclusion Location Types" v-if="isPromotionFlow && config.campaign.locationMode == fbEnums.locationMode.Hyperlocal" prop="adset.exclusionLocationTypes" key="adset.exclusionLocationTypes">
                <Row>
                  <Row-Col span="18">
                    <Select
                      multiple
                      placeholder="Select Location Types"
                      v-model="config.adset.exclusionLocationTypes"
                      :disabled="isExistingConfig || isReadOnlyMode"
                    >
                      <Option
                        v-for="(value, key) in fbEnums.locationTypes" :key="key"
                        :value="value"
                      >{{ key }}</Option>
                    </Select>
                  </Row-Col>
                </Row>
            </Form-Item>

            <Form-Item label="Store Page Type" v-if="!isPromotionFlow && config.campaign.locationMode == fbEnums.locationMode.Hyperlocal" prop="adset.pageType" key="adset.pageType">
                <Row>
                  <Row-Col span="18">
                    <Select
                      v-model="config.adset.pageType"
                      placeholder="Select a store page type"
                      :disabled="isExistingConfig || isReadOnlyMode"
                      clearable
                    >
                      <Option
                        v-for="(value, key) in assetTypes" :key="key"
                        :value="value"
                        >{{ key }}</Option
                      >
                    </Select>
                  </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Store Lead Form" prop="leadFormId" v-if="!isPromotionFlow && config.campaign.locationMode == fbEnums.locationMode.Hyperlocal && this.isLeadGenerationObjective(config)">
                <Row>
                  <Row-Col span="18">
                    <Select
                      v-model="config.storeLeadFormId"
                      placeholder="Select a Lead Form"
                      filterable
                      clearable
                      :disabled="isExistingConfig || isReadOnlyMode"
                    >
                      <Option
                        v-for="leadFormId in 50"
                        :key="leadFormId"
                        :value="leadFormId"
                        >Lead Form {{ leadFormId }}</Option
                      >
                    </Select>
                  </Row-Col>
                </Row>
              </Form-Item>

            <Form-Item label="Targeting Expansion" prop="adset.isTargetOptimizationEnabled" key="adset.isTargetOptimizationEnabled" v-if="showTargetingExpansion">
                <Row>
                <Row-Col span="9">
                    <Checkbox v-model="config.adset.isTargetOptimizationEnabled" :disabled="isReadOnlyMode"></Checkbox>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Ad formats" prop="adset.adformat" key="adset.adformat" v-if="isRnFConfig">
                <CheckboxGroup v-model="config.adset.adformat">
                <Checkbox v-for="(adFormat, index) in adsetAdFormats" :label="adFormat" :key="adFormat" :disabled="index == 0 || isReadOnlyMode">
                    <span>{{ adFormat }}</span>
                </Checkbox>
                <!-- <Checkbox v-if="config.campaign.objective != 12" :disabled="config.campaign.objective != 12" label="Image or Carousel">
                    <span>Image or Carousel</span>
                </Checkbox> -->
                <!-- <template v-if="config.campaign.objective == 12">
                    <Checkbox :disabled="config.campaign.objective == 12" label="Video">
                    <span>Video</span>
                    </Checkbox>
                    <Checkbox  disabled="true" label="360 Video">
                    <span>360 Video</span>
                    </Checkbox>
                    <Checkbox  disabled="true" label="Stories Carousel">
                    <span>Stories Carousel</span>
                    </Checkbox>
                    <Checkbox disabled="true" label="Instant Experience">
                    <span>Instant Experience</span>
                    </Checkbox>
                </template> -->
                </CheckboxGroup>
            </Form-Item>

            <Form-Item
                v-show="showDynamicCreative"
                label="Dynamic Creative"
                prop="adset.isDynamicCreative"
                key="adset.isDynamicCreative"
            >
                <i-switch
                :disabled="isExistingConfig || isReadOnlyMode"
                size="small"
                v-model="config.adset.isDynamicCreative">
                </i-switch>
            </Form-Item>

            <Form-Item
                v-show="config.adset.destination == fbEnums.destinationType.Messenger"
                label="Message template"
                prop="ad.messageTemplate"
                key="ad.messageTemplate"
            >
                <Row>
                <Row-Col span="18">
                    <Select
                    :placeholder="messageTemplatePlaceholder"
                    v-model="config.ad.messageTemplate.id"
                    not-found-text="No saved message templates"
                    :disabled="fetchingPageRelatedInformation || isReadOnlyMode"
                    >
                    <Option
                        v-for="(template, index) in savedMessageTemplates"
                        :key="index"
                        :value="template.id"
                    >{{ template.template_name }}</Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>

            <Form-Item v-if="showMobileAppSelect" label="App" prop="adset.app.appId" key="adset.app.appId">
                <Row>
                <Row-Col span="18">
                    <Select
                    :disabled="isExistingConfig || isReadOnlyMode"
                    placeholder="Select an app"
                    v-model="config.adset.app.appId"
                    not-found-text='Please select a Facebook Account'
                    >
                    <Option
                        v-for="(app, index) in linkedApps"
                        :key="index"
                        :value="app.Id"
                    >
                        {{ app.Name }}
                    </Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item v-if="showMobileAppSelect" label="App Store" prop="adset.app.storeUrl" key="adset.app.storeUrl">
                <Row>
                <Row-Col span="18">
                    <Select
                    :disabled="config.adset.app.appId == null || isExistingConfig || isReadOnlyMode"
                    placeholder="Select an app store"
                    v-model="config.adset.app.storeUrl"
                    not-found-text="No results"
                    >
                    <Option
                        v-for="(appStore, index) in computedAppStores"
                        :key="index"
                        :value="appStore.Value"
                    >
                        {{ appStore.Name }}
                    </Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Placements" prop="adset.selectedPlacementOption" key="adset.selectedPlacementOption">
                <Row>
                <Row-Col>
                    <RadioGroup v-model="config.adset.selectedPlacementOption">
                    <Radio label="Automatic" :disabled="isReadOnlyMode"></Radio>
                    <Radio label="Manual" :disabled="isReadOnlyMode"></Radio>
                    </RadioGroup>
                </Row-Col>
                <Row-Col v-if="config.adset.selectedPlacementOption == 'Manual'">
                    <Tree
                    :data="config.adset.placements"
                    show-checkbox multiple check-directly
                    >
                    </Tree>
                </Row-Col>
                </Row>
            </Form-Item>

            <Form-Item
              label="Exclude Instream Skippable Ads"
              prop="adset.excludeInstreamSkippableAds"
              key="adset.excludeInstreamSkippableAds"
              v-show="(!isPromotionFlow || !isWizardFlow)"
            >
                <Row>
                <Row-Col span="18">
                    <i-switch
                      :disabled="isExistingConfig || isReadOnlyMode"
                      size="small"
                      v-model="config.adset.excludeInstreamSkippableAds">
                    </i-switch>
                </Row-Col>
                </Row>
            </Form-Item>

            <template v-if="isDPAObjective(config)">
                <Form-Item label="Audience Type" prop="adset.selectedAudienceType" key="adset.selectedAudienceType">
                <Row>
                    <Row-Col>
                    <RadioGroup v-model="config.adset.selectedAudienceType">
                        <Radio label="Prospecting" :disabled="isReadOnlyMode"></Radio>
                        <Radio label="Retargeting" :disabled="isReadOnlyMode"></Radio>
                    </RadioGroup>
                    </Row-Col>
                </Row>
                </Form-Item>

                <Form-Item prop="adset.targetProductAudience" key="adset.targetProductAudience" v-if="config.adset.selectedAudienceType == 'Retargeting'">
                <!-- Inclusions Filter -->
                <Row>
                    <Row-Col span="4">
                    <span>Inclusions</span>
                    </Row-Col>
                    <Row-Col span="18">
                    <Row
                        v-for="(item, index) in config.adset.targetProductAudience.inclusions"
                        :key="index"
                        class="inclusion-exclusion-filter"
                        :disabled="isReadOnlyMode"
                    >
                        <Row-Col span="8">
                        <Select
                            v-model="item.value"
                            placeholder="Select an action"
                            :disabled="isReadOnlyMode"
                        >
                            <Option
                            v-for="(actionItem, actionIndex) in targetProductAudienceActions"
                            :key="actionIndex"
                            :value="actionItem.value"
                            >
                            {{ actionItem.action }}
                            </Option>
                        </Select>
                        </Row-Col>
                        <Row-Col span="3" offset="1">
                        <span>in the last</span>
                        </Row-Col>
                        <Row-Col span="3" offset="1">
                        <input-number
                            v-model="item.days"
                            type="number"
                            :min="1"
                        >
                        </input-number>
                        </Row-Col>
                        <Row-Col span="2" offset="2">
                        <span>days</span>
                        </Row-Col>
                        <Row-Col span="3" offset="1">
                        <Icon
                            type="md-close"
                            size="24"
                            class="iview-icons close-btn"
                            @click="removeDpaFilter(index, dpaFilterTypes.inclusion)"
                        />
                        </Row-Col>
                    </Row>
                    </Row-Col>
                    <Row-Col span="2">
                    <Icon
                        type="md-add"
                        size="24"
                        class="iview-icons add-btn"
                        @click="addDpaFilter(dpaFilterTypes.inclusion)"
                    />
                    </Row-Col>
                </Row>
                <!-- Exclusions Filter -->
                <Row>
                    <Row-Col span="4">
                    <span>Exclusions</span>
                    </Row-Col>
                    <Row-Col span="18">
                    <Row
                        v-for="(item, index) in config.adset.targetProductAudience.exclusions"
                        :key="index"
                        class="inclusion-exclusion-filter"
                    >
                        <Row-Col span="8">
                        <Select
                            v-model="item.value"
                            placeholder="Select an action"
                            :disabled="isReadOnlyMode"
                        >
                            <Option
                            v-for="(actionItem, actionIndex) in targetProductAudienceActions"
                            :key="actionIndex"
                            :value="actionItem.value"
                            >
                            {{ actionItem.action }}
                            </Option>
                        </Select>
                        </Row-Col>
                        <Row-Col span="3" offset="1">
                        <span>in the last</span>
                        </Row-Col>
                        <Row-Col span="3" offset="1">
                        <input-number
                            v-model="item.days"
                            type="number"
                            :min="1"
                        >
                        </input-number>
                        </Row-Col>
                        <Row-Col span="2" offset="2">
                        <span>days</span>
                        </Row-Col>
                        <Row-Col span="3" offset="1">
                        <Icon
                            type="md-close"
                            size="24"
                            class="iview-icons close-btn"
                            @click="removeDpaFilter(index, dpaFilterTypes.exclusion)"
                        />
                        </Row-Col>
                    </Row>
                    </Row-Col>
                    <Row-Col span="2">
                    <Icon
                        type="md-add"
                        size="24"
                        class="iview-icons add-btn"
                        @click="addDpaFilter(dpaFilterTypes.exclusion)"
                    />
                    </Row-Col>
                </Row>
                </Form-Item>
                <Form-Item prop="adset.targetProductAudience" key="adset.targetProductAudience" v-else-if="config.adset.selectedAudienceType == 'Prospecting'">
                <!-- Exclusions Filter -->
                <Row>
                    <Row-Col span="4">
                    <span>Exclusions</span>
                    </Row-Col>
                    <Row-Col span="18">
                    <Row
                        v-for="(item, index) in config.adset.targetProductAudience.exclusions"
                        :key="index"
                        class="inclusion-exclusion-filter"
                    >
                        <Row-Col span="8">
                        <Select
                            v-model="item.value"
                            placeholder="Select an action"
                            :disabled="isReadOnlyMode"
                        >
                            <Option
                            v-for="(actionItem, actionIndex) in targetProductAudienceActions"
                            :key="actionIndex"
                            :value="actionItem.value"
                            >
                            {{ actionItem.action }}
                            </Option>
                        </Select>
                        </Row-Col>
                        <Row-Col span="3" offset="1">
                        <span>in the last</span>
                        </Row-Col>
                        <Row-Col span="3" offset="1">
                        <input-number
                            v-model="item.days"
                            type="number"
                            :min="1"
                        >
                        </input-number>
                        </Row-Col>
                        <Row-Col span="2" offset="2">
                        <span>days</span>
                        </Row-Col>
                        <Row-Col span="3" offset="1">
                        <Icon
                            type="md-close"
                            size="24"
                            class="iview-icons close-btn"
                            @click="removeDpaFilter(index, dpaFilterTypes.exclusion)"
                        />
                        </Row-Col>
                    </Row>
                    </Row-Col>
                    <Row-Col span="2">
                    <Icon
                        type="md-add"
                        size="24"
                        class="iview-icons add-btn"
                        @click="addDpaFilter(dpaFilterTypes.exclusion)"
                    />
                    </Row-Col>
                </Row>
                </Form-Item>
            </template>

            <Form-Item label="Devices" prop="adset.devices" key="adset.devices" v-show="config.adset.selectedPlacementOption == 'Manual'">
                <Row>
                <Row-Col span="9">
                    <CheckboxGroup v-model="config.adset.devices">
                    <Checkbox :disabled="isDesktopDisabled || isReadOnlyMode" label="Desktop"></Checkbox>
                    <Checkbox :disabled="isMobileDisabled || isReadOnlyMode" label="Mobile"></Checkbox>
                    </CheckboxGroup>
                </Row-Col>
                </Row>
            </Form-Item>
            <div v-show="config.adset.devices.includes('Mobile') && config.adset.selectedPlacementOption == 'Manual'">
            <Form-Item label="Mobile Devices" prop="adset.mobileDevices" key="adset.mobileDevices" v-show="config.adset.devices.includes('Mobile') && config.adset.selectedPlacementOption == 'Manual'">
                <Row>
                <Row-Col span="9">
                    <Select
                    placeholder="Mobile Devices"
                    v-model="config.adset.mobileDevices"
                    not-found-text="No results"
                    :disabled="!config.bpseId || isReadOnlyMode"
                    @on-change="fetchMobileDevices"
                    >
                    <Option
                        v-for="(item, index) in mobileDevices"
                        :key="index"
                        :value="item"
                    >{{ item }}</Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Included devices" prop="adset.targeting.userDevice" key="adset.targeting.userDevice" v-show="config.adset.mobileDevices == 'Android devices only' || config.adset.mobileDevices === 'iOS devices only'">
                <Row>
                <Row-Col span="9">
                    <Select
                    multiple
                    placeholder="Enter a device name"
                    v-model="config.adset.targeting.userDevice"
                    not-found-text="No results"
                    filterable
                    :key="includedDeviceList.length"
                    :disabled="isReadOnlyMode"
                    >
                    <Option
                        v-for="(item, index) in includedDeviceList"
                        :key="index"
                        :value="item.name"
                    >{{ item.description }}</Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Excluded devices" prop="adset.targeting.excludedUserDevice" key="adset.targeting.excludedUserDevice" v-show="config.adset.mobileDevices == 'Android devices only' || config.adset.mobileDevices === 'iOS devices only'">
                <Row>
                <Row-Col span="9">
                    <Select
                    multiple
                    placeholder="Enter a device name"
                    v-model="config.adset.targeting.excludedUserDevice"
                    not-found-text="No results"
                    filterable
                    :disabled="isReadOnlyMode"
                    >
                    <Option
                        v-for="(item, index) in excludedDeviceList"
                        :key="index"
                        :value="item.name"
                    >{{ item.description }}</Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="OS Version (min)" prop="adset.targeting.minOsVersion" key="adset.targeting.minOsVersion" v-show="config.adset.mobileDevices == 'Android devices only' || config.adset.mobileDevices === 'iOS devices only'">
                <Row-Col span="9">
                    <Select
                    placeholder="Min"
                    v-model="config.adset.targeting.minOsVersion"
                    not-found-text="No results"
                    filterable
                    :disabled="isReadOnlyMode"
                    >
                    <Option
                        v-for="(item, index) in minOSList"
                        :key="index"
                        :value="item.version"
                    >{{ item.label }}</Option>
                    </Select>
                </Row-Col>
            </Form-Item>
            <Form-Item label="OS Version (max)" prop="adset.targeting.maxOsVersion" key="adset.targeting.maxOsVersion" v-show="config.adset.mobileDevices == 'Android devices only' || config.adset.mobileDevices === 'iOS devices only'">
                <Row>
                <Row-Col span="9">
                    <Select
                    placeholder="Max(Optional)"
                    v-model="config.adset.targeting.maxOsVersion"
                    not-found-text="No results"
                    filterable
                    :disabled="isReadOnlyMode"
                    >
                    <Option
                        v-for="(item, index) in maxOSList"
                        :key="index"
                        :value="item.version"
                    >{{ item.label }}</Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            </div>
            <Form-Item label="" prop="adset.onlyConnectedToWifi" key="adset.onlyConnectedToWifi" v-show="config.adset.selectedPlacementOption == 'Manual'">
                <Row>
                <Row-Col span="9">
                    <Checkbox v-model="config.adset.onlyConnectedToWifi" :disabled="isPromotionFlow">Only Connected to Wifi</Checkbox>
                </Row-Col>
                </Row>
            </Form-Item>
            <Divider orientation="left">Budget Settings</Divider>
            <Form-Item label="Budget Level" style="margin-bottom:0px !important" v-show="config.campaign.grouping == 1 && !isPromotionFlow" prop="budgetLevel" key="budgetLevel">
                <Row>
                <Row-Col span="18">
                    <RadioGroup v-model="config.budgetLevel">
                        <Radio :label="1" :disabled="isExistingConfig || isReadOnlyMode || config.campaign.isAdvantagePlusShoppingCampaign" >Campaign</Radio>
                        <Radio :label="2" :disabled="isExistingConfig || isReadOnlyMode || config.campaign.isAdvantagePlusShoppingCampaign">Adset</Radio>
                    </RadioGroup>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Budget Type" prop="campaign.budgetType" key="campaign.budgetType" v-show="config.campaign.buyType != 4">
                <Row>
                <Row-Col span="18">
                    <Select
                    :disabled="isExistingConfig || isReadOnlyMode || isWizardFlow"
                    placeholder="Budget Type"
                    v-model="config.campaign.budgetType"
                    not-found-text="No results"
                    @on-change="resetScheduleType"
                    >
                    <Option
                        v-for="(item, index) in budgetTypes"
                        :key="index"
                        :value="item.id"
                    >{{ item.name }}</Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            <div v-show="showSpendLimit && !isPromotionFlow">
                <Form-Item label="Spend Limit" prop="campaign.spendCap" key="campaign.spendCap">
                <Row>
                    <Row-Col span="18" :md="5">
                    <i-input type="number" v-model="config.campaign.spendCap" :number="true" placeholder="Spend Limit">
                        <span slot="prepend">{{bpCurrencySymbol}}</span>
                    </i-input>
                    </Row-Col>
                </Row>
                </Form-Item>
            </div>
            <Form-Item label="Ad Schedule" style="margin-bottom:0px !important" v-if="!isPromotionFlow" prop="adset.schedule.type" key="adset.schedule.type">
                <Row>
                <Row-Col span="18">
                    <RadioGroup v-model="config.adset.schedule.type">
                        <Radio :label="1" :disabled="isReadOnlyMode || config.isAdsetBudget">Always On</Radio>
                        <Radio :label="3" :disabled="config.campaign.budgetType!=2 || isReadOnlyMode || config.isAdsetBudget"><span v-if="config.campaign.budgetType!=2" v-tooltip="'To use custom schedule you  must use lifetime budget'"> Choose Schedule</span><span v-if="config.campaign.budgetType==2"> Choose Schedule</span></Radio>
                    </RadioGroup>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item  v-if="!isPromotionFlow&&config.adset.schedule.type==3&&config.campaign.budgetType==2" prop="adset.schedule" key="adset.schedule">
                <Row>
                <ScheduleConfiguration
                    :disabled="isReadOnlyMode"
                    @updateFacbeookCLCSchedule="updateSchedule"
                    :scheduleConfiguration="config.adset.schedule"
                    :isRnFConfig="config.adset.isRnFConfig"
                />
                </Row>
            </Form-Item>
            <Form-Item label="Campaign Bid Strategy" prop="campaign.bidStrategy" key="campaign.bidStrategy" v-show="config.campaign.buyType != 4">
                <Row>
                <Row-Col span="18">
                    <Select
                    placeholder="Bid Strategy"
                    v-model="config.campaign.bidStrategy"
                    not-found-text="No results"
                    :disabled="isReadOnlyMode"
                    >
                    <Option
                        v-for="(item, index) in bidStrategies"
                        :key="index"
                        :value="item.id"
                        :disabled="invalidBidStratergy(item.id, config)"
                    >{{ item.name }}</Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="AdGroup ROAS" prop="adset.roas" key="adset.roas" v-show='supportsRoas && !isPromotionFlow && config.campaign.bidStrategy == fbEnums.bidStrategy["Lowest Cost with Min Roas"]'>
                <Row>
                <Row-Col span="4">
                    <i-input v-model="config.adset.roas" :number='true' :disabled="isExistingConfig || isReadOnlyMode"/>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="AdGroup Bid Cap" prop="adset.bidCap" key="adset.bidCap" v-show="config.campaign.bidStrategy === fbEnums.bidStrategy['Lowest Cost with Bid Cap'] && config.campaign.buyType != 4">
                <Row>
                <Row-Col span="4">
                    <i-input v-model="config.adset.bidCap" :number='true' :disabled="isReadOnlyMode"/>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="AdGroup Cost Cap" prop="adset.costCap" key="adset.costCap" v-show="config.campaign.bidStrategy === fbEnums.bidStrategy['Lowest Cost with Cost Cap'] && config.campaign.buyType != 4">
                <Row>
                <Row-Col span="4">
                    <i-input v-model="config.adset.costCap" :number='true' :disabled="isReadOnlyMode"/>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Delivery Type" prop="campaign.deliveryType" key="campaign.deliveryType" v-show="config.campaign.buyType != 4 && !isPromotionFlow && !config.campaign.isAdvantagePlusShoppingCampaign">
                <Row>
                <Row-Col span="18">
                    <Select
                    placeholder="Delivery Type"
                    v-model="config.campaign.deliveryType"
                    not-found-text="No results"
                    :disabled="isReadOnlyMode"
                    >
                    <Option
                        v-for="(item, index) in deliveryTypes"
                        :key="index"
                        :value="item"
                        :disabled="invalidDeliveryType(item, config.campaign.bidStrategy) || item == 'day_parting'"
                    >{{ item }}</Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="AdGroup Scheduling" prop="adset.scheduling" key="adset.scheduling" v-show="config.campaign.buyType != 4 && !isPromotionFlow">
                <RadioGroup v-model="config.adset.scheduling.option">
                <Radio label="Run continuously starting today" :disabled="isPromotionFlow || isReadOnlyMode"></Radio>
                <Radio label="Use Line item schedule" :disabled="isPromotionFlow || isReadOnlyMode"></Radio>
                </RadioGroup>
                <div v-show="config.adset.scheduling.option === 'Set Schedule'">
                <Date-Picker
                    type="date"
                    v-model="config.adset.scheduling.startDate"
                    placeholder="Select Start date"
                    :disabled="isReadOnlyMode"
                ></Date-Picker>
                </div>
                <div v-show="config.adset.scheduling.option === 'Set Schedule'">
                <Date-Picker
                    type="date"
                    v-model="config.adset.scheduling.endDate"
                    placeholder="Select End date"
                    :disabled="isReadOnlyMode"
                ></Date-Picker>
                </div>
            </Form-Item>
            <Form-Item label="AdGroup Spend Limit" prop="adset.spendLimit" key="adset.spendLimit" v-show="config.campaign.buyType != 4 && config.budgetLevel == 1">
                <Row>
                <Row-Col span="8">
                    <Select placeholder="Type"
                    v-model="config.adset.spendLimit.option"
                    :disabled="isReadOnlyMode">
                    <Option v-for="option in adsetSpendLimitOptions" :key="option.id" :value="option.id">{{ option.name }}</Option>
                    </Select>
                </Row-Col>
                <Row-Col span="4" offset="1">
                    <i-input v-model="config.adset.spendLimit.min" placeholder="Min" :disabled="isReadOnlyMode"/>
                </Row-Col>
                <Row-Col span="4" offset="1">
                    <i-input v-model="config.adset.spendLimit.max" placeholder="Max" :disabled="isReadOnlyMode"/>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="AdGroup Existing Customers Budget Percentage" prop="adset.existingCustomersBudgetPercentage" key="adset.existingCustomersBudgetPercentage" v-show="config.campaign.isAdvantagePlusShoppingCampaign">
                <Row>
                <Row-Col span="6">
                    <i-input v-model="config.adset.existingCustomersBudgetPercentage" placeholder="Existing customers budget percentage" type="number" :disabled="isReadOnlyMode"/>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Optimize For" prop="adset.optimizeFor" key="adset.optimizeFor" v-show="!config.campaign.isAdvantagePlusShoppingCampaign">
                <Row>
                <Row-Col span="18">
                    <Select
                    placeholder="Optimization Goal"
                    v-model="config.adset.optimizeFor"
                    not-found-text="No results"
                    :disabled="config.campaign.objective === '' || isReadOnlyMode"
                    >
                    <Option
                        v-for="(item, index) in optimizationGoals"
                        :key="index"
                        :value="item.id"
                    >{{ item.name }}</Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item v-if="config.adset.optimizeFor == 29" label="Optimization App Events" prop="adset.optimizationAppEvents" key="adset.optimizationAppEvents">
                <Row>
                <Row-Col span="18">
                    <Select
                    placeholder="App Event"
                    v-model="config.adset.optimizationAppEvents"
                    not-found-text="No results"
                    :disabled="isReadOnlyMode"
                    >
                    <Option
                        v-for="(item, index) in optimizationAppEvents"
                        :key="index"
                        :value="item.id"
                    >{{ item.name }}</Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>

            <Form-Item label="When you get charged" prop="adset.billingEvent" key="adset.billingEvent">
                <Row>
                <Row-Col span="18">
                    <Select
                    placeholder="Billing Event"
                    not-found-text="No results"
                    v-model="config.adset.billingEvent"
                    :disabled="config.campaign.objective === '' || isProductCatalogObjective || isReadOnlyMode"
                    >
                    <Option
                        v-for="(item, index) in billingEvents"
                        :key="index"
                        :value="item.id"
                        :disabled="(item.id != 3 && config.campaign.bidStrategy == fbEnums.bidStrategy['Lowest Cost with Cost Cap'])"
                    >{{ item.name }}</Option>
                    <!-- Allow only impressions for 'Lowest Cost with Cost Cap' bid stratergy -->
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Reach and Frequency Balance" prop="adset.reachAndFrequencyBalance" key="adset.reachAndFrequencyBalance" v-show="showFrequencyCap">
                <Row>
                <Row-Col span="18">
                    <Select
                    placeholder="Reach and Frequency Balance"
                    not-found-text="No results"
                    v-model="config.adset.reachAndFrequencyBalance.type"
                    :disabled="isReadOnlyMode"
                    >
                    <Option
                        v-for="(item, index) in reachAndFrequencyBalances"
                        :key="index"
                        :value="item.id"
                    >{{ item.name }}</Option>
                    </Select>
                </Row-Col>
                <Row-Col span="18">
                    <Row style="margin-top:10px;">
                    <Row-Col span="18" v-show="config.adset.reachAndFrequencyBalance.type == 1">
                        <Row-Col span="6"><span>Frequency Cap</span></Row-Col>
                        <Row-Col span="3"><i-input v-model="config.adset.reachAndFrequencyBalance.cap" :disabled="isReadOnlyMode"/></Row-Col>
                        <Row-Col span="3" style="text-align:center"><span>per</span></Row-Col>
                        <Row-Col span="3"><i-input v-model="config.adset.reachAndFrequencyBalance.days" :disabled="isReadOnlyMode"/></Row-Col>
                        <Row-Col span="3" style="text-align:center"><span>Days</span></Row-Col>
                    </Row-Col>
                    </Row>
                </Row-Col>
                </Row>
            </Form-Item>
          <template v-if="isWizardFlow">
            <Divider orientation="left">KPI</Divider>
            <Form-Item label="KPI" prop="kpiMetricId">
              <Row>
                <Row-Col span="18">
                  <Select
                    v-model="config.kpiMetricId"
                    placeholder="Select a kpi metric"
                    :transfer="true"
                    filterable
                    :disabled="isEditPromoMode || isExistingConfig || isReadOnlyMode"
                  >
                    <Option
                      v-for="dataParameter in dataParameters"
                      :key="dataParameter.id"
                      :value="dataParameter.id"
                      >{{ dataParameter.name }}</Option
                    >
                  </Select>
                </Row-Col>
              </Row>
            </Form-Item>
          </template>

            <Divider v-show="!isPromotionFlow || isReadOnlyMode" orientation="left">Attribution Settings</Divider>
            <Form-Item label="Click Through Window" prop="adset.clickAttributionWindow" key="adset.clickAttributionWindow" v-show="!isPromotionFlow || isReadOnlyMode">
                <Row>
                <Row-Col span="18">
                    <Select :disabled="isPromotionFlow || isReadOnlyMode || !clickWindows || clickWindows.length == 0" placeholder="Click Through" clearable v-model="config.adset.clickAttributionWindow" not-found-text="No results">
                    <Option
                        v-for="item in clickWindows"
                        :value="item"
                        :key="item"
                    >{{ item }} Day(s)</Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="View Through Window" prop="adset.viewAttributionWindow" key="adset.viewAttributionWindow" v-show="!isPromotionFlow || isReadOnlyMode">
                <Row>
                <Row-Col span="18">
                    <Select :disabled="isPromotionFlow || isReadOnlyMode || !viewWindows || viewWindows.length == 0" placeholder="View Through" clearable v-model="config.adset.viewAttributionWindow" not-found-text="No results">
                    <Option
                        v-for="item in viewWindows"
                        :value="item"
                        :key="item"
                    >{{ item }} Day</Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            <Divider orientation="left">Tracking Settings</Divider>
            <Form-Item label="Tracking Pixel" prop="adset.trackingPixel" key="adset.trackingPixel">
                <Row>
                <Row-Col span="18" v-if="!isPromotionFlow">
                    <Select
                    placeholder
                    v-model="config.adset.trackingPixel"
                    not-found-text="No results"
                    clearable
                    :disabled="isReadOnlyMode"
                    >
                    <Option
                        v-for="(item, index) in trackingPixels"
                        :value="item.id"
                        :key="index"
                    >{{ item.name }} ({{ item.id }})</Option>
                    </Select>
                </Row-Col>
                <Row-Col span="18" v-else>
                    <Select
                    v-model="config.adset.trackingPixel"
                    :transfer = "true"
                    disabled
                    >
                    <Option
                        :value="config.adset.trackingPixel"
                    >{{ config.adset.trackingPixel }}</Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item v-show="!hideFBConversionDomain" label="" prop="adset.useAdLinkForConvDomain" key="adset.useAdLinkForConvDomain">
                <Row>
                <Row-Col span="18">
                    <Checkbox v-model="config.adset.useAdLinkForConvDomain">Use domain from destination url</Checkbox>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item v-show="!hideFBConversionDomain && !disableFBConversionDomain"  label="Conversion Domain" prop="adset.conversionDomain" key="adset.conversionDomain">
                <Row>
                <Row-Col span="18" v-if="!isPromotionFlow">
                    <Auto-Complete
                        v-model="config.adset.conversionDomain"
                        :data="conversionDomains"
                        :filter-method="conversionDomainsfilter"
                        :transfer = "true"
                        not-found-text="No results"
                        :disabled="disableFBConversionDomain"
                        placeholder="Select or Enter a Domain">
                    </Auto-Complete>
                </Row-Col>
                <Row-Col span="18" v-else>
                    <Select
                    v-model="config.adset.conversionDomain"
                    :transfer = "true"
                    disabled
                    >
                    <Option
                        :value="config.adset.conversionDomain"
                    >{{ config.adset.conversionDomain }}</Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="App Event" prop="adset.appEvent" key="adset.appEvent">
                <Row>
                <Row-Col span="18" v-if="!isPromotionFlow">
                    <Select placeholder clearable v-model="config.adset.appEvent" not-found-text="No results" :disabled="isReadOnlyMode">
                    <Option
                        v-for="(item, index) in appEvents"
                        :value="item.id"
                        :key="index"
                    >{{ item.name }}</Option>
                    </Select>
                </Row-Col>
                <Row-Col v-else span="18">
                    <Select v-model="config.adset.appEvent" :transfer="true" disabled>
                    <Option :value="config.adset.appEvent || ''">
                        {{ config.adset.appEvent }}
                    </Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Offline Event" prop="adset.offlineEvent" key="adset.offlineEvent" v-show="!isPromotionFlow">
                <Row>
                <Row-Col span="18">
                    <Select
                    placeholder
                    v-model="config.adset.offlineEvent"
                    not-found-text="No results"
                    clearable
                    :disabled="isReadOnlyMode"
                    >
                    <Option
                        v-for="(item, index) in offlineEvents"
                        :value="item.id"
                        :key="index"
                    >{{ item.name }}</Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Custom Event Type" v-show="isConversionsObjective(config) || isDPAObjective(config)" prop="adset.customEventType" key="adset.customEventType">
                <Row>
                <Row-Col span="18">
                    <Select
                    placeholder
                    v-model="config.adset.customEventType"
                    not-found-text="No results"
                    clearable
                    filterable
                    :disabled="isReadOnlyMode"
                    :key="config.campaign.isAdvantagePlusShoppingCampaign"
                    >
                    <OptionGroup label="Standard">
                            <Option
                                v-for="(item, index) in filteredStandardEventTypes"
                                :value="item"
                                :key="index"
                            >{{ item }}</Option>
                        </OptionGroup>
                        <OptionGroup label="Custom" v-if="customEventTypes.length>0 && !config.campaign.isAdvantagePlusShoppingCampaign">
                            <Option
                            v-for="item in customEventTypes"
                            :value="item.id"
                            :key="item.id"
                            :disabled="item.is_archived || item.is_unavailable"
                            >{{ item.name }} ({{ item.id }})</Option>
                        </OptionGroup>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="Configuration Name" prop="name" key="name" v-show="!isPromotionFlow || isReadOnlyMode">
                <Row>
                <Row-Col span="18">
                    <i-input v-model="config.name" :disabled="isPromotionFlow || isReadOnlyMode" />
                </Row-Col>
                </Row>
            </Form-Item>
        </div>
    </Form>
    </div>
</template>
<script>
import {
  Divider,
  Row,
  Col,
  RadioGroup,
  Radio,
  Form,
  FormItem,
  Select,
  Option,
  Input,
  CheckboxGroup,
  Checkbox,
  DatePicker,
  Tree,
  Switch,
  OptionGroup,
  Icon,
  InputNumber,
  AutoComplete,
  Alert
} from "iview";
import * as fbEnums from "../Constants/FacebookEnums.js";
import { AssetType } from "../Constants/PromotionEnums.js";
import { mapState, mapMutations } from "vuex";
import { APIService } from "./../ApiService.js";
import {
  getRnfAdformatsByObjective,
  getRnfPlacements
} from "./../columnConfig.js";
import ModalMessageMixin from "../../Promotion/Mixins/ModalMessageMixin.js";
import CLHelperMethodsMixin from "../Mixins/CLHelperMethods.js"
import FacebookCampaignLauncherConfig from "../Mixins/FacebookCampaignLauncherConfig.js"
import ScheduleConfiguration from "./ScheduleConfiguration.vue";
import { parseDomain } from "parse-domain";
import ApiService from "../../Promotion/ApiService.js";
export default {
  props: {
    defaultFormConfig: Object,
    inPromoDashboard: {
      type: Boolean,
      default: false
    },
    isEditPromoMode: {
      type: Boolean,
      default: false
    },
    isReadOnly: { type: Boolean, default: false },
    isExistingConfig: { type: Boolean, default: false },
    showConfigForm: { default: false },
    isPromotionFlow: { type: Boolean, default: false },
    nomenclatureMacro: { default: null },
    loadExistingConfigId: { default: "" },
    openConfigurationModal: { type: Boolean, default: false },
    hasOrganicPost: { type: Boolean, default: false }
  },
  data () {
    const placementsValidator = (rule, value, callback) => {
      if (value == "Manual") {
        let placements = [...this.config.adset.placements.filter(element => {
          return element.checked || element.indeterminate
        })];
        if (placements.length == 0) { callback(new Error("Please select atleast one placement")) }
        callback();
      } else {
        callback();
      }
    }
    const productFilterValidator = (rule, value, callback) => {
      if (this.config.adset.selectedAudienceType != "Retargeting") {
        callback();
      }

      let isInclusionsEmpty = value.inclusions.length == 0;
      let isExclusionsEmpty = value.exclusions.length == 0;

      if (isInclusionsEmpty && isExclusionsEmpty) {
        callback(new Error("Please add at least one inclusion and at least one exclusion"));
      } else if (isInclusionsEmpty) {
        callback(new Error("Please add at least one inclusion"));
      } else if (isExclusionsEmpty) {
        callback(new Error("Please add at least one exclusion"));
      } else {
        callback();
      }
    }
    const trackingPixelValidator = (rule, value, callback) => {
      if (this.isConversionsObjective(this.config)) {
        if (!value) { callback(new Error("Tracking Pixel is required for this objective")) }
      }
      callback();
    }
    const spendLimitValidator = (rule, value, callback) => {
      let min = value.min;
      let max = value.max;
      if (min === "" && max === "") { callback(); }
      // if (max === "" || min === "") { callback(); }
      min = min !== "" ? parseFloat(min) : "";
      max = max !== "" ? parseFloat(max) : "";
      if (isNaN(min)) { callback(new Error("Minimum spend limit must be a number")); }
      if (isNaN(max)) { callback(new Error("Maximum spend limit must be a number")); }
      if (min < 0 || max < 0) { callback(new Error("Spend limits must be greater than 0")); }
      if (max === 0) {
        if (this.config.adset.spendLimit.option != 1) {
          callback(new Error("Maximum spend limit must be greater than 0%"));
        } else {
          callback(new Error("Maximum spend limit must be atleast $1.00"));
        }
      }
      if (max !== "" && min > max) { callback(new Error("Minimum adset spend limit must be less than the maximum spend limit")); }
      if (this.config.adset.spendLimit.option !== 1) {
        // in case of percentage, ensure the min and max limits are within 100
        if (min > 100 || max > 100) {
          callback(new Error("Spend limit percentage must be less than 100%"))
        }
      }
      callback();
    }
    const adsetBidCapValidator = (rule, value, callback) => {
      if (this.config.campaign.bidStrategy === fbEnums.bidStrategy["Lowest Cost with Bid Cap"]) {
        if (value <= 0) {
          callback(new Error("Bid amount must be greater than 0"));
        }
        if (typeof (value) != 'number') {
          callback(new Error("Bid amount must be a number"));
        }
      }
      callback();
    }
    const adsetCostCapValidator = (rule, value, callback) => {
      if (this.config.campaign.bidStrategy === fbEnums.bidStrategy["Lowest Cost with Cost Cap"]) {
        if (value <= 0) {
          callback(new Error("Cap amount must be greater than 0"));
        }
        if (typeof (value) != 'number') {
          callback(new Error("Cap amount must be a number"));
        }
      }
      callback();
    }
    const customEventTypeValidator = (rule, value, callback) => {
      if (this.isConversionsObjective(this.config)) {
        if (!value) { callback(new Error("Custom Event Type is required")) }
      }
      callback();
    }
    const appValidator = (rule, value, callback) => {
      if (this.isAppInstallsObjective(this.config)) {
        if (!value) { callback(new Error("Please choose an App")) }
      }
      callback();
    }
    const appStoreValidator = (rule, value, callback) => {
      if ((this.isAppInstallsObjective(this.config) || this.config.adset.destination == fbEnums.destinationType.App) && !this.isDPAObjective(this.config)) {
        if (!value) { callback(new Error("Please choose an App store")) }
      }
      callback();
    }
    const reachAndFrequencyValidator = (rule, value, callback) => {
      if (this.config.campaign.buyType == 4 && this.config.adset.reachAndFrequencyBalance.type == 1) {
        let cap = value.cap;
        let days = value.days;
        if (cap === "" && days === "") { callback(new Error("Frequency cap and Days are required")); } else if (cap === "") { callback(new Error("Frequency cap is required")); } else if (days === "") { callback(new Error("Days is required")); }
        cap = cap !== "" ? parseFloat(cap) : "";
        days = days !== "" ? parseFloat(days) : "";
        if (isNaN(cap)) { callback(new Error("Frequency cap must be a number")); }
        if (isNaN(days)) { callback(new Error("Days must be a number")); }
        callback();
      }
      callback();
    }
    const deviceValidator = (rule, value, callback) => {
      if (this.isDeviceValidationRequired && value.length <= 0) {
        callback(new Error("Select atleast one device"));
      }
      callback()
    }
    const adformatValidator = (rule, value, callback) => {
      if (this.isRnFConfig && this.config.adset.selectedPlacementOption == "Manual" && this.adformatValidatorRequired) {
        if (this.isPostEngagementObjective(this.config)) {
          if (this.isEqualArray(["Image or Carousel"], value)) {
            this.ProcessPlacementsByAdFormat(["Facebook"], false)
            this.config.adset.placements.forEach(ele => {
              if (!["Messenger"].includes(ele.title)) {
                ele.disabled = false
              }
            })
            if (!this.config.adset.devices.includes("Desktop")) { this.config.adset.devices.push("Desktop") }
            this.isDesktopDisabled = false
            this.isMobileDisabled = false
            this.isDeviceValidationRequired = true
          }
        } else if (this.isConversionsObjective(this.config)) {
          if (this.isEqualArray(["Image or Carousel"], value)) {
            this.ProcessPlacementsByAdFormat(["Facebook"], false)
            this.config.adset.placements.forEach(ele => {
              if (!["Messenger", "Audience network"].includes(ele.title)) {
                ele.disabled = false
              }
            })
            if (!this.config.adset.devices.includes("Desktop")) { this.config.adset.devices.push("Desktop") }
            this.isDesktopDisabled = false
            this.isMobileDisabled = false
            this.isDeviceValidationRequired = true
          }
        } else if (this.isTrafficObjective(this.config)) {
          if (this.isEqualArray(["Image or Carousel"], value)) {
            this.ProcessPlacementsByAdFormat(["Facebook"], false)
            this.config.adset.placements.forEach(ele => {
              if (!["Messenger", "Audience network"].includes(ele.title)) {
                ele.disabled = false
              }
            })
            if (!this.config.adset.devices.includes("Desktop")) { this.config.adset.devices.push("Desktop") }
            this.isDesktopDisabled = false
            this.isMobileDisabled = false
            this.isDeviceValidationRequired = true
          }
        } else if (this.isVideoViewsObjective(this.config)) {
          if (this.isEqualArray(["Video", "Instant Experience", "360 Video"], value)) {
            this.ProcessPlacementsByAdFormat(["Facebook"], false)
            this.config.adset.devices = []
            this.isDesktopDisabled = true
            this.isMobileDisabled = true
            this.isDeviceValidationRequired = false
          } else if (this.isEqualArray(["Video", "Instant Experience", "Stories Carousel"], value)) {
            this.ProcessPlacementsByAdFormat(["Instagram"], false)
            this.config.adset.devices = []
            this.isDesktopDisabled = true
            this.isMobileDisabled = true
            this.isDeviceValidationRequired = false
          } else if (this.isEqualArray(["Video", "Instant Experience"], value)) {
            this.ProcessPlacementsByAdFormat(["Audience network", "Messenger"], true)
            this.config.adset.devices = []
            this.isDesktopDisabled = true
            this.isMobileDisabled = true
            this.isDeviceValidationRequired = false
          } else if (this.isEqualArray(["Video"], value)) {
            this.ProcessPlacementsByAdFormat(["Facebook"], false)
            this.config.adset.placements.forEach(ele => {
              if (!["Messenger"].includes(ele.title)) {
                ele.disabled = false
              }
            })
            if (!this.config.adset.devices.includes("Desktop")) { this.config.adset.devices.push("Desktop") }
            this.isDesktopDisabled = false
            this.isMobileDisabled = false
            this.isDeviceValidationRequired = true
          } else if (this.isEqualArray(["Video", "Stories Carousel"], value)) {
            this.ProcessPlacementsByAdFormat(["Audience network", "Messenger"], true)
            this.config.adset.devices = []
            this.isDesktopDisabled = true
            this.isMobileDisabled = true
            this.isDeviceValidationRequired = false
          } else if (this.isEqualArray(["Video", "360 Video"], value)) {
            this.ProcessPlacementsByAdFormat(["Facebook"], false)
            if (!this.config.adset.devices.includes("Desktop")) { this.config.adset.devices.push("Desktop") }
            this.isDesktopDisabled = false
            this.isMobileDisabled = false
            this.isDeviceValidationRequired = true
          }
        } else if (this.isReachObjective(this.config)) {
          if (this.isEqualArray(["Image or Carousel"], value)) {
            this.ProcessPlacementsByAdFormat(["Facebook"], false)
            this.config.adset.placements.forEach(ele => {
              if (!["Messenger"].includes(ele.title)) {
                ele.disabled = false
              }
            })
            if (!this.config.adset.devices.includes("Desktop")) { this.config.adset.devices.push("Desktop") }
            this.isDesktopDisabled = false
            this.isMobileDisabled = false
            this.isDeviceValidationRequired = true
          }
        }
      }
      this.adformatValidatorRequired = true
      callback();
    }
    const conversionDomainValidator = (rule, value, callback) => {
      if (value && value.trim()) {
        value = value.trim();
        if (this.config.adset.conversionDomain != value) {
          this.config.adset.conversionDomain = value;
        }
        let parsedDomainObj = parseDomain(value);
        let correctDomain = (parsedDomainObj.hostname != "" && parsedDomainObj.topLevelDomains)
          ? `${parsedDomainObj.domain}.${parsedDomainObj.topLevelDomains.toString().replaceAll(',', '.')}` : "";
        if ((!parsedDomainObj.domain && parsedDomainObj.hostname != "") || value != correctDomain) {
          callback(new Error("The conversion domain must be a valid domain and should contain only the first and second level domains"));
        }
      } else {
        this.config.adset.conversionDomain = "";
      }
      callback();
    }
    const spendCapValidator = (rule, value, callback) => {
      if (value || value === 0) {
        if (value <= 0) {
          return callback(new Error("Spend Cap must be greater than 0"), 1000);
        } else if (value % 1 !== 0) {
          return callback(new Error("Please enter an integer"), 1000);
        } else {
          callback();
        }
      }
      callback();
    }
    const adsetRoasValidator = (rule, value, callback) => {
      if (this.supportsRoas && this.config.campaign.bidStrategy === fbEnums.bidStrategy["Lowest Cost with Min Roas"]) {
        if (value < 0.01 || value > 1000) {
          callback(new Error("Roas must be between 0.01 to 1000"));
        }
        if (typeof (value) != 'number') {
          callback(new Error("Roas must be a number"));
        }
      }
      callback();
    }
    return {
      config: this.defaultFormConfig,
      adAccounts: [],
      pages: [],
      setPageActorOverride: "useApp",
      instagramAccounts: [],
      savedMessageTemplates: {},
      fetchingPageInfoFailed: false,
      fetchingPageRelatedInformation: false,
      fbEnums,
      assetTypes: AssetType,
      bpTimeZone: "",
      adsetAdFormats: [],
      adsetSpendLimitOptions: [
        { id: 1, name: "Absolute" },
        { id: 2, name: "% of Total Campaign Budget" },
        { id: 3, name: "% of Average Adgroup Budget" },
        { id: 4, name: "% of Weighted Average" }
      ],
      facebookObjectives: [
        { id: 3, name: "Page Likes" },
        { id: 6, name: "Event Responses" },
        { id: 7, name: "Post Engagement" },
        { id: 8, name: "Conversions" },
        { id: 11, name: "Traffic" },
        { id: 12, name: "Video Views" },
        { id: 13, name: "Product Catalog Sales" },
        { id: 15, name: "App Installs" },
        { id: 16, name: "Brand Awareness" },
        { id: 18, name: "Reach" },
        { id: 14, name: "Lead Generation" },
        { id: 20, name: "Messages" }
      ],
      optimizationAppEvents: [
        { id: 1, name: "Achieve Level", apiEvent: "LEVEL_ACHIEVED" },
        { id: 2, name: "Add Payment Info", apiEvent: "ADD_PAYMENT_INFO" },
        { id: 3, name: "Add to Cart", apiEvent: "ADD_TO_CART" },
        { id: 4, name: "Add to Wishlist", apiEvent: "ADD_TO_WISHLIST" },
        { id: 5, name: "Complete Registration", apiEvent: "COMPLETE_REGISTRATION" },
        { id: 6, name: "Content View", apiEvent: "CONTENT_VIEW" },
        { id: 7, name: "Initiate Checkout", apiEvent: "INITIATED_CHECKOUT" },
        { id: 8, name: "Purchase", apiEvent: "PURCHASE" },
        { id: 9, name: "Rate", apiEvent: "RATE" },
        { id: 10, name: "Search", apiEvent: "SEARCH" },
        { id: 11, name: "Spend Credits", apiEvent: "SPENT_CREDITS" },
        { id: 12, name: "Tutorial Completion", apiEvent: "TUTORIAL_COMPLETION" },
        { id: 13, name: "Unlocked Achievement", apiEvent: "ACHIEVEMENT_UNLOCKED" }
      ],
      campaignGroupings: [
        { id: 1, name: "Based on Line Item" },
        { id: 2, name: "Based on Line Item and Target" },
        { id: 3, name: "Based on Line Item Fraction" }
      ],
      budgetTypes: [{ id: 1, name: "Daily" }, { id: 2, name: "Lifetime" }],
      destinationEnumsByObjective: {
        Website: {
          enum: fbEnums.destinationType.Page,
          objectives: [
            fbEnums.objectives.Conversions,
            fbEnums.objectives.Traffic,
            fbEnums.objectives.Engagement,
            fbEnums.objectives.Sales,
            fbEnums.objectives.Leads,
            fbEnums.objectives['Traffic Outcome']
          ]
        },
        App: {
          enum: fbEnums.destinationType.App,
          objectives: [
            fbEnums.objectives.Conversions,
            fbEnums.objectives.Traffic,
            fbEnums.objectives.Engagement,
            fbEnums.objectives.Sales,
            fbEnums.objectives.Leads,
            fbEnums.objectives['Traffic Outcome']
          ]
        },
        "Click to Messenger": {
          enum: fbEnums.destinationType.Messenger,
          objectives: [
            fbEnums.objectives.Conversions,
            fbEnums.objectives.Traffic,
            fbEnums.objectives.Messages,
            fbEnums.objectives.Engagement,
            fbEnums.objectives.Sales,
            fbEnums.objectives.Leads,
            fbEnums.objectives['Traffic Outcome']
          ]
        },
        "Sponsored message": {
          enum: fbEnums.destinationType.SponsoredMessage,
          objectives: []
        },
        WhatsApp: {
          enum: fbEnums.destinationType.WhatsApp,
          objectives: [
            fbEnums.objectives.Conversions,
            fbEnums.objectives.Traffic,
            fbEnums.objectives.Messages,
            fbEnums.objectives.Engagement,
            fbEnums.objectives.Sales,
            fbEnums.objectives['Traffic Outcome']
          ]
        },
        "Instagram Direct": {
          enum: fbEnums.destinationType.InstagramDirect,
          objectives: []
        },
        "Lead Forms": {
          enum: fbEnums.destinationType.LeadForm,
          objectives: [fbEnums.objectives['Lead Generation']]
        },
        "Instant Forms": {
          enum: fbEnums.destinationType.Ad,
          objectives: [
            fbEnums.objectives['Lead Generation'],
            fbEnums.objectives.Leads
          ]
        },
        "Calls": {
          enum: fbEnums.destinationType.PhoneCall,
          objectives: [
            fbEnums.objectives['Lead Generation'],
            fbEnums.objectives.Leads,
            fbEnums.objectives['Traffic Outcome']
          ]
        },
        "Post": {
          enum: fbEnums.destinationType.Post,
          objectives: [fbEnums.objectives.Engagement]
        },
        "Video": {
          enum: fbEnums.destinationType.Video,
          objectives: [fbEnums.objectives.Engagement]
        },
        "Event": {
          enum: fbEnums.destinationType.OnEvent,
          objectives: [fbEnums.objectives.Engagement]
        },
        "Page": {
          enum: fbEnums.destinationType.OnPage,
          objectives: [fbEnums.objectives.Engagement]
        },
        "Instant Form and Messenger": {
          enum: fbEnums.destinationType.LeadFormMessenger,
          objectives: [fbEnums.objectives['Lead Generation'], fbEnums.objectives.Leads]
        }
      },
      mobileDevices: [
        "All Mobile devices",
        "Feature phones only",
        "Android devices only",
        "iOS devices only"
      ],
      deviceList: [],
      includedDeviceList: [],
      excludedDeviceList: [],
      mobileOsVersions: [],
      iOSDevices: [],
      minOSList: [],
      maxOSList: [],
      bidStrategies: this.getDefaultBidStrategies(),
      deliveryTypes: ["Standard", "Accelerated", "day_parting"],
      billingEventsEnum: {
        1: "App Installs",
        2: "Clicks",
        3: "Impressions",
        4: "Link Clicks",
        5: "Offer Claims",
        6: "Page Likes",
        7: "Post Engagement",
        8: "Video Views",
        9: "None",
        10: "Mrc Video Views",
        11: "Completed Video Views",
        12: "Video Views 15s",
        13: "2 Second Continuous Video Views",
        14: "Store Visits",
        15: "Thruplay",
        16: "Unsupported"
      },
      trackingPixels: [],
      conversionDomains: [],
      hideFBConversionDomain: true,
      appEvents: [],
      offlineEvents: [],
      conversionPixels: [],
      standardEventTypes: [
        "Rate",
        "Tutorial Completion",
        "Contact",
        "Customize Product",
        "Donate",
        "Find Location",
        "Schedule",
        "Start Trial",
        "Submit Application",
        "Subscribe",
        "Add to Cart",
        "Add to Wishlist",
        "Initiated Checkout",
        "Add Payment Info",
        "Purchase",
        "Lead",
        "Complete Registration",
        "Content View",
        "Search",
        "Service Booking Request",
        "Messaging Conversation Started 7d",
        "Level Achieved",
        "Achievement Unlocked",
        "Spent Credits",
        "D2 Retention",
        "D7 Retention",
        "Other"
      ],
      customEventTypes: [],
      placementOptions: ["Automatic", "Manual"],
      campaignConfigLibrary: {
        columns: [
          { title: "Name", key: "Name" },
          { title: "Campaign(s) Created", key: "CampaignsCreated" },
          { title: "Update", key: "Update" },
          { title: "Status", key: "Status" }
        ],
        data: []
      },
      reachAndFrequencyBalances: [{ id: 1, name: "Custom" }, { id: 2, name: "Default" }],
      isDesktopDisabled: false,
      isMobileDisabled: false,
      isDeviceValidationRequired: true,
      adformatValidatorRequired: false,
      unwatch: {
        adsetSpendLimit: function () {}
      },
      validationRules: {
        name: [
          {
            required: true,
            type: "string",
            message: "The Configuration name is required",
            trigger: "blur"
          }
        ],
        facebookPageId: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('Page is required'));
              }
              callback();
            }
          }
        ],
        bpseId: [{ required: true, message: "Account is required" }],
        "campaign.objective": [
          {
            required: true,
            type: "number",
            message: "Objective is required",
            trigger: "change"
          }
        ],
        "campaign.name": [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('Campaign name is required'));
              }
              callback();
            }
          }
        ],
        "campaign.productCatalog": [
          {
            required: false,
            trigger: "change"
          }
        ],
        "adset.name": [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('Adset name is required'));
              }
              callback();
            }
          }
        ],
        "adset.mobileDevices": [
          {
            required: true,
            message: "Select at least one device type",
            trigger: "change"
          }
        ],
        // 'adset.placements': [{ required: true, min: 1, message: 'Select atleast one placement', trigger: 'change' }],
        "adset.devices": [
          {
            validator: deviceValidator,
            trigger: "change"
          }
        ],
        "campaign.bidStrategy": [
          {
            type: "number",
            required: true,
            message: "Campaign bid strategy is required",
            trigger: "change"
          }
        ],
        "adset.bidCap": [
          {
            trigger: "blur",
            validator: adsetBidCapValidator
          }
        ],
        "adset.costCap": [
          {
            trigger: "blur",
            validator: adsetCostCapValidator
          }
        ],
        "adset.schedule": [{
          validator: (rule, value, callback) => {
            if (this.isWithinRange) {
              callback(new Error("Please provide a valid time slot"))
            }
            callback()
          }
        }],
        "adset.existingCustomersBudgetPercentage": [{
          validator: (rule, value, callback) => {
            if (Number(value) && (Number(value) < 0 || Number(value) > 100)) {
              callback(new Error("Please provide a value between 0 and 100"))
            }
            callback()
          }
        }],
        "adset.optimizeFor": [
          {
            type: "number",
            required: true,
            message: "Choose your optimization goal",
            trigger: "change"
          }
        ],
        "adset.optimizationAppEvents": [
          {
            type: "number",
            required: true,
            validator: (rule, value, callback) => {
              if (this.config.adset.optimizeFor == 29 && !(this.config.adset.optimizationAppEvents > 0)) {
                callback(new Error("Please select an app event."))
              }
              callback()
            },
            trigger: "change"
          }
        ],
        "adset.billingEvent": [
          {
            type: "number",
            required: true,
            message: "Choose when you get charged",
            trigger: "change"
          }
        ],
        "adset.selectedPlacementOption": [
          {
            // required: true,
            // message: "Select a placement type",
            // trigger: "blur"
            validator: placementsValidator, trigger: "blur"
          }
        ],
        "adset.targetProductAudience": [
          {
            validator: productFilterValidator,
            required: true
          }
        ],
        "adset.trackingPixel": [
          {
            validator: trackingPixelValidator, trigger: "blur"
          }
        ],
        "adset.spendLimit": [
          {
            validator: spendLimitValidator, trigger: "change"
          }
        ],
        "adset.customEventType": [
          {
            validator: customEventTypeValidator,
            trigger: "change",
            required: true
          }
        ],
        "adset.reachAndFrequencyBalance": [
          {
            validator: reachAndFrequencyValidator,
            trigger: "blur"
          }
        ],
        "adset.adformat": [
          {
            validator: adformatValidator,
            trigger: "change"
          }
        ],
        "adset.app.appId": [
          {
            validator: appValidator,
            trigger: "change"
          }
        ],
        "adset.app.storeUrl": [
          {
            required: true,
            validator: appStoreValidator,
            trigger: "change"
          }
        ],
        "adset.destination": [
          {
            validator: (rule, value, callback) => {
              if (!this.showDestination) { callback() }
              if (!value || value == fbEnums.destinationType.Undefined) {
                callback(new Error("Please select a destination"))
              }
              if (value == fbEnums.destinationType.WhatsApp) {
                if (this.fetchingPageInfoFailed) {
                  callback(new Error("Failed to check if the selected page is connected to a WhatsApp account"))
                }
                if (!this.selectedPageHasWhatsapp) {
                  callback(new Error("Selected Page isn't connected to a WhatsApp account"))
                }
              }
              callback()
            }
          }
        ],
        "ad.messageTemplate": [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (
                value.id <= 0 &&
                this.config.adset.destination == fbEnums.destinationType.Messenger
              ) {
                if (Object.keys(this.savedMessageTemplates).length > 0) {
                  callback(new Error("Please select an existing template"))
                }
                callback(new Error("There are no message templates saved for the page selected"))
              }
              callback()
            }
          }
        ],
        "adset.conversionDomain": [
          {
            validator: conversionDomainValidator,
            trigger: "blur",
            type: "url"
          }
        ],
        "campaign.spendCap": [
          {
            validator: spendCapValidator,
            trigger: "blur",
            type: "integer"
          }
        ],
        kpiMetricId: [
          {
            required: true,
            type: "integer",
            message: "The Kpi Metric is needed is required",
            trigger: "blur"
          }
        ],
        storeLeadFormId: [
          {
            trigger: "change",
            type: "integer"
          }
        ],
        "adset.roas": [
          {
            trigger: "blur",
            validator: adsetRoasValidator
          }
        ]
      },
      linkedApps: [],
      defaultPlacements: this.defaultFormConfig.adset.placements,
      loadingExistingConfig: false,
      selectedPageHasWhatsapp: false,
      targetProductAudienceActions: [
        {
          action: "Added to cart",
          value: "AddToCart"
        },
        {
          action: "Viewed",
          value: "ViewContent"
        },
        {
          action: "Purchased",
          value: "Purchase"
        }
      ],
      liveProductCatalogs: [],
      dxProductCatalogs: [],
      dpaFilterTypes: {
        inclusion: 1,
        exclusion: 2
      },
      isWithinRange: false,
      timeZoneTypes: [
        {
          value: "USER",
          label: "USER"
        },
        {
          value: "ADVERTISER",
          label: "ADVERTISER"
        }
      ],
      selectedTimeZoneType: '',
      areCatalogsBeingFetched: false,
      dayPartingScheduleOrderKeys: [],
      clickWindows: [],
      viewWindows: [],
      showSpendLimit: true,
      fetchCatalogsApiCallFailed: false,
      isReadOnlyMode: this.isReadOnly
    };
  },
  components: {
    Divider,
    Row,
    "Row-Col": Col,
    RadioGroup,
    Radio,
    Form,
    FormItem,
    Select,
    Option,
    'i-input': Input,
    CheckboxGroup,
    Checkbox,
    DatePicker,
    Tree,
    'i-switch': Switch,
    OptionGroup,
    Icon,
    'input-number': InputNumber,
    ScheduleConfiguration,
    AutoComplete,
    Alert
  },
  mixins: [ModalMessageMixin, CLHelperMethodsMixin, FacebookCampaignLauncherConfig],
  watch: {
    "setPageActorOverride": function (value) {
      if (value == "useApp") {
        this.config.adset.pageActorOverride = false;
      }
      if (value == "usePage") {
        this.config.adset.pageActorOverride = true;
      }
    },
    "openConfigurationModal": function (value) {
      if (value) {
        this.loadConfig(this.loadExistingConfigId)
      }
    },
    "loadExistingConfigId": function (id) {
      this.loadConfig(id)
    },
    "config.adset.schedule.type": function (value) {
      if (value == 3) {
        if (this.config.adset.schedule.dayParting_schedules.length == 0) {
          this.config.adset.schedule.dayParting_schedules = [ {
            start_minute: 0,
            end_minute: 1440,
            days: [0, 1, 2, 3, 4, 5, 6],
            timezone_type: 'USER'
          }]
        }
      } else {
        this.config.adset.schedule.dayParting_schedules = []
      }
    },
    "config.adset.dsaBeneficiary": function (value) {
      if (!this.config.adset.isPayorDifferent) {
        this.config.adset.dsaPayor = this.config.adset.dsaBeneficiary
      }
    },
    "config.campaign.isAdvantagePlusShoppingCampaign": function (value) {
      if (value) {
        let defaultBidStrategies = this.getDefaultBidStrategies()
        this.config.budgetLevel = 2
        this.config.campaign.adgroupPerLocation = "all"
        this.bidStrategies = defaultBidStrategies.filter(bidStrategy => {
          return bidStrategy.id == fbEnums.bidStrategy["Lowest Cost"]
        })
        this.config.adset.grouping = ["Audience and Placement"]
        if (this.optimizationGoals && this.optimizationGoals.length > 0) {
          this.config.adset.optimizeFor = this.optimizationGoals[0].id
        }
      } else {
        this.bidStrategies = this.getDefaultBidStrategies();
      }
      if (this.supportsRoas && !this.isPromotionFlow) {
        const bidStrategyExists = this.bidStrategies.some(
          strategy => strategy.id === fbEnums.bidStrategy["Lowest Cost with Min Roas"]
        );
        if (!bidStrategyExists) {
          this.bidStrategies.push({
            id: fbEnums.bidStrategy["Lowest Cost with Min Roas"],
            name: "Lowest Cost with Min Roas"
          });
        }
      }
    },
    "config.bpseId": function (value) {
      this.linkedApps = []
      if (!this.loadingExistingConfig && !this.inPromoDashboard) {
        this.config.adset.app.appId = null
        this.config.adset.appEvent = ""
      }
      if (!this.loadingExistingConfig && !this.isWizardFlow) {
        this.trackingPixels = [];
        this.config.adset.trackingPixel = "";
      }
      if (!value || this.inPromoDashboard) { return; }
      let account = {}
      if (this.accountAssetsMapper && Object.keys(this.accountAssetsMapper).length > 0) {
        account = this.accountAssetsMapper[this.config.bpseId].assets;
      } else {
        let bpse = this.accounts.find(bpse => bpse.id == this.config.bpseId);
        account = JSON.parse(bpse.accountAssets);
      }
      if (!account.Data || account.Data.length == 0) {
        this.linkedApps = []
        this.pages = []
        this.instagramAccounts = []
        return
      }
      this.linkedApps = account.Data[0].MobileApps
      this.pages = account.Data[0].Pages;
      this.instagramAccounts = account.Data[0].Instagram;
      if (!this.isPromotionFlow && this.isCatalogSupportedForConfig(this.config, true)) {
        this.fetchProductCatalogs();
      }
      if (this.isConversionsObjective(this.config)) {
        this.getCustomConversions();
      }
      if (!this.isPromotionFlow) {
        this.loadChannelMacros();
      }

      // this.SET_BPSEID(this.config.accountId);
      APIService.get(
        "/App/CampaignLauncher/GetAccountRelatedEntities/" +
          this.businessProfileId +
          "?bpseId=" +
          this.config.bpseId
      ).then(response => {
        let appEvents = JSON.parse(response.appEvents || "{}");
        let offlineEvents = JSON.parse(response.offlineEvents || "{}");
        let pixels = JSON.parse(response.pixels || "{}");
        this.trackingPixels = this.conversionPixels = pixels;
        this.appEvents = appEvents;
        this.offlineEvents = offlineEvents;
      });
      this.getMobileDeviceListFromAPI();
    },
    "config.facebookPageId": {
      immediate: true,
      handler: function (newValue, oldValue) {
        if (!this.loadingExistingConfig && !this.inPromoDashboard) {
          this.config.ad.messageTemplate = { id: "", name: "", messageBody: {} }
        }
        if (!newValue) { return }
        this.savedMessageTemplates = {}
        this.selectedPageHasWhatsapp = false
        this.fetchingPageInfoFailed = false
        this.fetchingPageRelatedInformation = true;
        this.$emit("fetchingPageRelatedInformation", this.fetchingPageRelatedInformation)
        // if (newValue != oldValue) {
        //   this.config.instagramAccountId = "";
        // }
        APIService.getFbPageRelatedInformation(
          this.businessProfileId,
          this.config.bpseId,
          newValue
        ).then(response => {
          let pageInfo = response.data.data
          try {
            let template = pageInfo.messenger_ads_default_page_welcome_message
            template.page_welcome_message_body = JSON.parse(template.page_welcome_message_body)
            this.$set(this.savedMessageTemplates, template.id, template)
            this.config.ad.messageTemplate.id = template.id
          } catch {}
          if (pageInfo.has_whatsapp_business_number || pageInfo.has_whatsapp_number) {
            this.selectedPageHasWhatsapp = true
          }
        }).catch(() => {
          this.fetchingPageInfoFailed = true
        }).finally(() => {
          this.fetchingPageRelatedInformation = false;
          this.$emit("fetchingPageRelatedInformation", this.fetchingPageRelatedInformation)
        });
      }
    },
    "config.campaign.bidStrategy": function (value) {
      if (this.loadingExistingConfig) { return }
      if (value === fbEnums.bidStrategy["Lowest Cost with Bid Cap"]) {
        this.config.adset.bidCap = 1;
        this.config.adset.costCap = 0;
      } else if (value === fbEnums.bidStrategy["Lowest Cost with Cost Cap"]) {
        this.config.adset.costCap = 1;
        this.config.adset.bidCap = 0;
        this.config.campaign.deliveryType = "Standard";
        this.config.adset.billingEvent = 3; // Impressions
        // These are the default values accepted by FaceBook for Cost Cap
        // https://developers.facebook.com/docs/marketing-api/bidding/overview/bid-strategy#cap
      } else if (value === fbEnums.bidStrategy["Lowest Cost"] && this.config.adset.schedule.type == 1) {
        this.config.campaign.deliveryType = "Standard";
        this.config.adset.bidCap = 0;
        this.config.adset.costCap = 0;
      }
      if (value === fbEnums.bidStrategy["Lowest Cost with Min Roas"]) {
        this.config.adset.roas = 1;
      } else {
        this.config.adset.roas = 0;
      }
      this.$refs["formCampaignConfig"].validateField("adset.bidCap");
      this.$refs["formCampaignConfig"].validateField("adset.costCap");
      this.$refs["formCampaignConfig"].validateField("adset.roas");
    },
    isWithinRange: function (value) {
      this.$refs["formCampaignConfig"].validateField("adset.schedule");
    },
    "config.campaign.objective": function () {
      if (this.config.campaign.buyType == 4 && this.config.campaign.objective) {
        var adformats = getRnfAdformatsByObjective(this.config);
        this.adsetAdFormats = adformats;
        this.config.adset.adformat = [this.adsetAdFormats[0]];
        this.processPlacementsForRnf();
      } else {
        if (!this.isPromotionFlow && this.isCatalogSupportedForConfig(this.config, true)) {
          if ((this.config.campaign.objective == fbEnums.objectives["App Installs"] && this.config.campaign.productCatalog) ||
              this.config.campaign.objective == fbEnums.objectives["Product Catalog Sales"] ||
              this.config.campaign.objective == fbEnums.objectives["Product Catalog Sales"]) {
            this.config.adset.destination = fbEnums.destinationType.App;
          }
          this.fetchProductCatalogs();
        } else if (this.isStoreVisitsObjective(this.config)) {
          if (this.config.campaign.locationMode == fbEnums.locationMode.PageSets) {
            this.config.campaign.adgroupPerLocation = "all";
          }
        }
        if (this.isConversionsObjective(this.config)) {
          this.getCustomConversions();
        }
      }
      if (!this.isStoreVisitsObjective(this.config) && this.config.campaign.locationMode == fbEnums.locationMode.PageSets) {
        this.config.campaign.locationMode = "Standard";
      }
      if (!this.loadingExistingConfig) {
        this.config.campaign.bidStrategy = 1;
      }
      this.isWizardFlow ? this.updatePlacements(this.isExistingConfig) : this.updatePlacements();
      this.clickWindows = this.getAttributionClicks(this.config, this.fbEnums.optimizationGoalsEnum)
      this.viewWindows = this.getAttributionViews(this.config, this.fbEnums.optimizationGoalsEnum)
      if (this.config.adset.clickAttributionWindow) {
        if (!this.clickWindows.includes(this.config.adset.clickAttributionWindow)) {
          this.config.adset.clickAttributionWindow = null
        }
      }
      if (this.config.adset.viewAttributionWindow) {
        if (!this.viewWindows.includes(this.config.adset.viewAttributionWindow)) {
          this.config.adset.viewAttributionWindow = null
        }
      }
      if (!this.isLeadGenerationObjective(this.config)) {
        this.config.storeLeadFormId = 0;
      }
    },
    "config.adset.selectedPlacementOption": function () {
      if (this.config.campaign.buyType == 4) {
        this.processPlacementsForRnf();
      }
    },
    "config.adset.adformat": function () {
      if (this.config.campaign.buyType == 4) {
        this.processPlacementsForRnf();
      }
    },
    "config.campaign.buyType": function () {
      if (!this.loadingExistingConfig) {
        this.config.campaign.objective = ""
      }
    },
    "config.campaign.locationMode": function () {
      if (this.isStoreVisitsObjective(this.config) && this.config.campaign.locationMode == fbEnums.locationMode.PageSets) {
        this.config.campaign.adgroupPerLocation = "all";
      }

      if (this.config.campaign.locationMode != fbEnums.locationMode.Hyperlocal) {
        this.config.adset.pageType = null;
        this.config.storeLeadFormId = 0;
      }
    },
    isRnFConfig () {
      if (this.isRnFConfig) {
        this.config.adset.targetSelection = "Individually"
      }
    },
    "config.adset.optimizeFor": function (optimizationGoal) {
      if (this.isLeadGenerationObjective(this.config)) {
        // Billing Event as Impression when OptimzationGoal is LeadGeneration or Calls
        if (optimizationGoal == 17 || optimizationGoal == 30) {
          this.config.adset.billingEvent = 3
        } else {
          this.config.adset.billingEvent = 4
        }
      } else if (this.isDPAObjective(this.config)) {
        if (optimizationGoal != 8) {
          this.config.adset.billingEvent = 3;
        }
      }
      this.clickWindows = this.getAttributionClicks(this.config, this.fbEnums.optimizationGoalsEnum)
      this.viewWindows = this.getAttributionViews(this.config, this.fbEnums.optimizationGoalsEnum)
      if (this.config.adset.clickAttributionWindow) {
        if (!this.clickWindows.includes(this.config.adset.clickAttributionWindow)) {
          this.config.adset.clickAttributionWindow = null
        }
      }
      if (this.config.adset.viewAttributionWindow) {
        if (!this.viewWindows.includes(this.config.adset.viewAttributionWindow)) {
          this.config.adset.viewAttributionWindow = null
        }
      }
    },
    "config.adset.targeting.userDevice": function (newVal, oldVal) {
      var self = this;
      var result = [];
      if (newVal.length - oldVal.length == 1) {
        var listOfDevices = self.deviceList.filter(function (obj) {
          return newVal.some(function (obj2) {
            return obj.name == obj2;
          });
        });
        let userSelection = newVal.filter(x => !oldVal.includes(x))
        var input = listOfDevices.filter(x => x.name == userSelection)[0];
        if (oldVal.length > 0 && input) {
          if (self.config.adset.targeting.isAndroidOs) {
            result = self.mobileDeviceParentCheckAndorid(listOfDevices, input)
          } else {
            result = self.mobileDeviceParentCheckIOS(listOfDevices, input)
          }
          self.config.adset.targeting.userDevice = result.map(m => m.name);
        }
      }
      self.excludedDeviceList = self.deviceList.filter(function (obj) {
        return !self.config.adset.targeting.userDevice.some(function (obj2) {
          return obj.name == obj2;
        });
      });
    },
    "config.adset.targeting.excludedUserDevice": function (newVal, oldVal) {
      var self = this;
      var result = [];
      if (newVal.length - oldVal.length == 1) {
        var listOfDevices = self.deviceList.filter(function (obj) {
          return newVal.some(function (obj2) {
            return obj.name == obj2;
          });
        });
        let userSelection = newVal.filter(x => !oldVal.includes(x))
        var input = listOfDevices.filter(x => x.name == userSelection)[0];
        if (oldVal.length > 0 && input) {
          if (self.config.adset.targeting.isAndroidOs) {
            result = self.mobileDeviceParentCheckAndorid(listOfDevices, input)
          } else {
            result = self.mobileDeviceParentCheckIOS(listOfDevices, input)
          }
          self.config.adset.targeting.excludedUserDevice = result.map(m => m.name);
        }
      }
      self.includedDeviceList = self.deviceList.filter(function (obj) {
        return !self.config.adset.targeting.excludedUserDevice.some(function (obj2) {
          return obj.name == obj2;
        });
      });
    },
    "config.adset.targeting.minOsVersion": function () {
      var osString = this.getOSVersionString()
      if (osString) this.config.adset.targeting.userOs = [osString]
      this.maxOSList = this.mobileOsVersions.filter(x => parseFloat(x.version) >= parseFloat(this.config.adset.targeting.minOsVersion));
    },
    "config.adset.targeting.maxOsVersion": function () {
      var osString = this.getOSVersionString()
      if (osString) this.config.adset.targeting.userOs = [osString]
      if (this.config.adset.targeting.maxOsVersion) {
        this.minOSList = this.mobileOsVersions.filter(x => parseFloat(x.version) <= parseFloat(this.config.adset.targeting.maxOsVersion));
      }
    },
    "config.adset.destination": function () {
      // Reset placements. Disable Audience network for 'Click to messenger'
      this.config.adset.placements = JSON.parse(JSON.stringify(this.defaultPlacements))
      if (this.config.adset.destination == fbEnums.destinationType.Messenger) {
        let audienceNet = this.config.adset.placements.find(
          x => x.title == "Audience network"
        )
        audienceNet.disabled = true
      }
      if (this.config.adset.destination == fbEnums.destinationType.App) {
        if (this.config.adset.pageActorOverride || false) {
          this.setPageActorOverride = "usePage"
        } else {
          this.setPageActorOverride = "useApp"
        }
      }
    },
    "config.ad.messageTemplate.id": function (id) {
      if (!(id in this.savedMessageTemplates)) { return }
      this.config.ad.messageTemplate.name = this.savedMessageTemplates[id].template_name
      this.config.ad.messageTemplate.messageBody = this.savedMessageTemplates[id].page_welcome_message_body
    },
    "config.adset.customEventType": function (id) {
      if (this.isConversionsObjective(this.config)) {
        var customConversionSelected = this.customEventTypes.find(x => x.id == id)
        if (customConversionSelected && this.config.adset.customConversion) {
          this.config.adset.customConversion.id = customConversionSelected.id
          this.config.adset.customConversion.name = customConversionSelected.name
          this.config.adset.customConversion.pixelId = customConversionSelected.pixel.id
          this.config.adset.customConversion.customEventType = customConversionSelected.custom_event_type
          this.config.adset.customConversion.pixelRule = customConversionSelected.rule
          this.config.adset.customConversion.customEventStr = customConversionSelected.customEventStr
        }
      }
    },
    "config.adset.trackingPixel": function (newPixel) {
      if (!newPixel) {
        this.hideFBConversionDomain = true;
        this.config.adset.conversionDomain = "";
      } else {
        if (this.config.bpseId && newPixel) {
          APIService.getConversionDomains(this.config.bpseId, newPixel)
            .then(response => {
              if (response && response.data && response.data.data) {
                this.conversionDomains = [...new Set(response.data.data.map(e => parseDomain(e))
                  .filter(e => (e.type == "LISTED"))
                  .map(e => `${e.domain}.${e.topLevelDomains.toString().replaceAll(',', '.')}`))]
              } else {
                this.conversionDomains = [];
              }
              this.hideFBConversionDomain = false;
            })
            .catch((error) => {
              this.hideFBConversionDomain = false;
              this.conversionDomains = [];
              this.$Message.error({ background: true, content: "Error while fetching conversion domains." });
              throw error;
            });
        }
      }
    },
    "config.campaign.budgetType": function (budgetType) {
      if (budgetType == 1) {
        this.showSpendLimit = true;
      } else {
        this.showSpendLimit = false;
        this.config.campaign.spendCap = undefined;
      }
    },
    "config.campaign.productCatalog": function (value) {
      if (value) {
        // find value from list of live catalogs
        var filteredCatalogs = this.liveProductCatalogs.filter(e => {
          return e.Id == value
        })
        if (filteredCatalogs.length > 0) {
          this.config.campaign.isCatalogSegment = filteredCatalogs[0].IsCatalogSegment;
        }
      } else {
        this.config.campaign.isCatalogSegment = false;
      }
    },
    "config.campaign.isAutomatedAppAdsEnabled": function (isAutomatedAppAdsEnabled) {
      if (isAutomatedAppAdsEnabled && this.isAppInstallsObjective(this.config)) {
        this.bidStrategies = [
          { id: fbEnums.bidStrategy["Lowest Cost"], name: "Lowest Cost" },
          { id: fbEnums.bidStrategy["Lowest Cost with Bid Cap"], name: "Lowest Cost with Bid Cap" },
          { id: fbEnums.bidStrategy["Lowest Cost with Min Roas"], name: "Lowest Cost with Min Roas" }
        ];
      } else {
        this.bidStrategies = this.getDefaultBidStrategies();
      }
    },
    "config.budgetLevel": function (value) {
      if (value == 2) {
        this.config.isAdsetBudget = true
        this.config.adset.schedule.type = 1
      } else {
        this.config.isAdsetBudget = false
        this.config.adset.spendLimit.min = ""
        this.config.adset.spendLimit.max = ""
      }
    },
    "config.campaign.grouping": function (value) {
      if (value != 1) {
        this.config.budgetLevel = 1
        this.config.isAdsetBudget = false
      }
    },
    "defaultFormConfig": function (value) {
      this.config = JSON.parse(JSON.stringify(value))
    },
    "supportsRoas": function (value) {
      if (value && !this.isPromotionFlow) {
        const bidStrategyExists = this.bidStrategies.some(
          strategy => strategy.id === fbEnums.bidStrategy["Lowest Cost with Min Roas"]
        );

        if (!bidStrategyExists) {
          this.bidStrategies.push({
            id: fbEnums.bidStrategy["Lowest Cost with Min Roas"],
            name: "Lowest Cost with Min Roas"
          });
        }
      } else {
        this.bidStrategies = this.bidStrategies.filter(bidStrategy => {
          return bidStrategy.id !== fbEnums.bidStrategy["Lowest Cost with Min Roas"]
        })
      }
    }
  },
  mounted () {
    // if(this.loadedConfig && this.loadedConfig.bpseId)
    //   this.config = this.loadedConfig;
    if (this.isLivePromotion) {
      this.isReadOnlyMode = true;
    }
    if (this.isPromotionFlow) {
      if (this.config.bpseId) {
        this.getMobileDeviceListFromAPI(true)
      }
      if (this.isWizardFlow) {
        let account = {}
        if (this.accountAssetsMapper && Object.keys(this.accountAssetsMapper).length > 0) {
          account = this.accountAssetsMapper[this.config.bpseId].assets;
        } else {
          let bpse = this.accounts.find(bpse => bpse.id == this.config.bpseId);
          account = JSON.parse(bpse.accountAssets);
        }
        if (!account.Data || account.Data.length == 0) {
          this.linkedApps = []
          this.pages = []
          this.instagramAccounts = []
          return
        }
        this.linkedApps = account.Data[0].MobileApps
        this.pages = account.Data[0].Pages;
        this.instagramAccounts = account.Data[0].Instagram;
      }
    }
    this.setupWatchers();
  },
  computed: {
    ...mapState([
      "campaignLauncherConfigs",
      "businessProfileId",
      "accounts",
      "accountAssetsMapper",
      "publisher",
      "accountMacros",
      "nomenclatureSettings",
      "bpCurrencySymbol",
      "isWizardFlow",
      "dataParameters",
      "leadForms",
      "isLivePromotion"
    ]),
    disableFBConversionDomain () {
      return this.config.adset.useAdLinkForConvDomain;
    },
    IsAdgNameDisabledForPromotion () {
      if (this.isPromotionFlow) {
        if (!this.isLivePromotion && this.config.campaign.locationMode == fbEnums.locationMode.Hyperlocal) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    },
    buyingTypes () {
      var buyingTypes = [{ id: 1, name: "Auction" }, { id: 4, name: "Reach and Frequency" }]
      return buyingTypes;
    },
    filteredStandardEventTypes () {
      if (!this.loadExistingConfigId) {
        this.$nextTick(() => {
          this.config.adset.customEventType = undefined
        })
      }
      if (this.config.campaign.isAdvantagePlusShoppingCampaign) {
        return this.standardEventTypes.filter((eventType) => {
          return eventType === "Purchase" || eventType === "Add to Cart"
        })
      } else {
        return this.standardEventTypes
      }
    },
    isOdaxEnabledAccount () {
      if (!this.accounts) {
        return false
      }
      let selectedFacebookAccount = this.accounts.filter(e => {
        return e.id == this.config.bpseId;
      });
      return selectedFacebookAccount && selectedFacebookAccount.length > 0 && selectedFacebookAccount[0].isOdaxEnabled;
    },
    showTargetingExpansion () {
      // Only specific objectives
      return this.isConversionsObjective(this.config) ||
        this.isAppInstallsObjective(this.config) ||
        this.isLeadGenerationObjective(this.config) ||
        this.isTrafficObjective(this.config) ||
        this.isPostEngagementObjective(this.config)
    },
    optimizationGoals () {
      let goals = []
      let optimizationGoals = this.getOptimizationGoalsByObjective(this.config)
      if (optimizationGoals) {
        if (this.isRnFConfig) {
          goals = optimizationGoals["reachAndFrequency"]
            .map(x => {
              return { id: x, name: fbEnums.optimizationGoalsEnum[x] }
            })
        } else {
          goals = optimizationGoals["auction"]
            .map(x => {
              return { id: x, name: fbEnums.optimizationGoalsEnum[x] }
            })
          if (this.config.adset.destination == fbEnums.destinationType.Messenger) {
            goals = goals.filter(x => x.id != 20)
          }
          if (this.isLeadGenerationObjective(this.config)) {
            if (this.config.adset.destination == fbEnums.destinationType.PhoneCall) {
              // Optimization Goal as Calls for DestinationType PhoneCall
              goals = goals.filter(x => x.id == 30)
            } else {
              // Optimization Goal as LeadGeneration for other DestinationTypes
              goals = goals.filter(x => x.id == 17)
            }
          }
        }
      }
      if (this.config.campaign.bidStrategy === fbEnums.bidStrategy['Lowest Cost with Cost Cap']) {
        let unsupportedGoals = [7, 14, 22, 29];
        // Impressions, Reach, Value, App Events

        goals = goals.filter(x => !unsupportedGoals.includes(x.id));
      }
      if (goals.length == 1) {
        this.config.adset.optimizeFor = goals[0].id
      }

      if (this.config.campaign.isAutomatedAppAdsEnabled) {
        let unsupportedGoals = [];
        switch (this.config.campaign.bidStrategy) {
          case fbEnums.bidStrategy["Lowest Cost with Bid Cap"]:
            unsupportedGoals = [31, 22];
            break;

          case fbEnums.bidStrategy["Lowest Cost with Min Roas"]:
            unsupportedGoals = [31, 26, 2];
            break;
        }

        goals = goals.filter(x => !unsupportedGoals.includes(x.id));
      }

      if (this.supportsRoas &&
      this.config.campaign.bidStrategy == fbEnums.bidStrategy["Lowest Cost with Min Roas"] &&
      !this.isPromotionFlow) {
        // Only Value is supported as Goal with this settings. So removing the others.
        let supportedGoals = [22];
        goals = goals.filter(x => supportedGoals.includes(x.id));
        this.config.adset.optimizeFor = 22
      }
      return goals
    },
    destinations () {
      if (this.isRnFConfig || !this.config.facebookPageId) { return [] }
      let destinationsToShow = []
      for (let [dest, destInfo] of Object.entries(this.destinationEnumsByObjective)) {
        if (this.isPromotionFlow && dest == "WhatsApp") {
          // Don't show WhatsApp for promo mode
          continue
        }
        if (destInfo.objectives.includes(this.config.campaign.objective)) {
          destinationsToShow.push({
            id: destInfo.enum,
            name: dest
          })
        }
      }
      if (
        destinationsToShow.length > 0 &&
        destinationsToShow.findIndex(x => x.id == this.config.adset.destination) == -1
      ) {
        this.config.adset.destination = destinationsToShow[0].id
      }
      return destinationsToShow
    },
    billingEvents () {
      let billingEvents = []
      var billingEventsByObjective = this.getBillingEventsByObjective(this.config)
      if (billingEventsByObjective) {
        if (this.isRnFConfig) {
          billingEvents = billingEventsByObjective["reachAndFrequency"]
            .map(x => {
              return { id: x, name: this.billingEventsEnum[x] }
            })
        } else {
          billingEvents = billingEventsByObjective["auction"]
            .map(x => {
              return { id: x, name: this.billingEventsEnum[x] }
            })
        }
      }
      if (billingEvents.length == 1) {
        this.config.adset.billingEvent = billingEvents[0].id
      }
      return billingEvents
    },
    computedFacebookObjectives () {
      if (this.isOdaxEnabledAccount) {
        if (this.isRnFConfig) {
          return { "Awareness": 21 }
        }
        return Object.entries(fbEnums.objectives).reduce(
          (res, [objective, enumId]) => {
            if (enumId > 20) {
              res[objective] = enumId;
            }
            return res;
          },
          {}
        );
      }
      if (this.isRnFConfig) {
        this.config.campaign.budgetType = 2;
        const rnfObjectives = new Set([
          "Post Engagement",
          "Conversions",
          "Traffic",
          "Video Views",
          "Reach",
          "Brand Awareness"
        ]);
        return Object.entries(fbEnums.objectives).reduce(
          (res, [objective, enumId]) => {
            if (rnfObjectives.has(objective)) {
              res[objective] = enumId;
            }
            return res;
          },
          {}
        );
      } else {
        let self = this;
        return Object.entries(fbEnums.objectives).reduce(
          (res, [objective, enumId]) => {
            if (enumId <= 20) {
              res[objective] = enumId;
            } if (self.isWizardFlow) {
              delete res["Store Visits"];
              delete res["Event Responses"];
              delete res["Product Catalog Sales"];
              if (self.hasOrganicPost) {
                delete res["Page Likes"];
                delete res["App Installs"];
                delete res["Lead Generation"];
                delete res["Messages"];
              }
            }
            return res;
          },
          {}
        );
      }
    },
    computedAppStores () {
      if (!this.config.adset.app.appId) {
        return [];
      }
      const linkedApp = this.linkedApps.filter(
        appObj => appObj.Id === this.config.adset.app.appId
      );
      return linkedApp.length > 0 ? (linkedApp[0].ObjectStoreUrls || []) : [];
    },
    isRnFConfig () {
      return this.config.campaign.buyType == 4
    },
    showFrequencyCap () {
      const toShow =
        this.isRnFConfig ||
        (this.config.campaign.buyType == 1 && // buy type = Auction
        this.isReachObjective(this.config) &&
          this.isReachObjective(this.config));
      if (!toShow && this.config.adset.reachAndFrequencyBalance.type == 1) {
        this.config.adset.reachAndFrequencyBalance = {
          type: 2,
          cap: "2",
          days: "7"
        };
      }
      return toShow;
    },
    appSelectPlaceholder () {
      return (this.config.bpseId) ? 'There are no apps linked to your ad account' : 'Please select a Facebook Account'
    },
    messageTemplatePlaceholder () {
      if (this.fetchingPageInfoFailed) {
        return "Failed to load saved templates for selected page"
      }
      return this.fetchingPageRelatedInformation
        ? "Loading saved templates for selected page ..."
        : "Select an existing template";
    },
    showDynamicCreative () {
      let destinatonObjective = !(this.config.campaign.objective == fbEnums.objectives.Awareness);
      let toShow =
        this.config.campaign.buyType == 1 &&
        this.getDynamicCreatives(this.config) != null &&
        !this.isPromotionFlow &&
        (this.config.adset.destination == fbEnums.destinationType.Page || !destinatonObjective);
      if (!toShow) {
        this.config.adset.isDynamicCreative = false;
      }
      return toShow;
    },
    showDestination () {
      // Only for traffic, Conversions, Messages and Lead Generation objective
      let objectivesToShow = [
        fbEnums.objectives.Conversions,
        fbEnums.objectives.Traffic,
        fbEnums.objectives.Messages,
        fbEnums.objectives.Engagement,
        fbEnums.objectives.Leads,
        fbEnums.objectives.Sales,
        fbEnums.objectives['Traffic Outcome']
      ]
      let toShow = !this.isRnFConfig && (objectivesToShow.includes(this.config.campaign.objective) && !this.isDPAObjective(this.config))
      if (!toShow) {
        if (this.isDPAObjective(this.config)) {
          this.config.adset.destination = fbEnums.destinationType.Undefined
        }
        this.config.ad.messageTemplate = { id: "", name: "", messageBody: {} }
      }
      return toShow
    },
    showMobileAppAlert () {
      // show if objective is App Installs or destination is App
      if (
        (this.isAppInstallsObjective(this.config) ||
          this.config.adset.destination == fbEnums.destinationType.App) &&
        this.config.bpseId
      ) {
        return this.linkedApps.length == 0;
      }
      return false;
    },
    showMobileAppSelect () {
      if (this.isDPAObjective(this.config, true)) {
        return this.linkedApps.length > 0
      }
      return (
        (this.isAppInstallsObjective(this.config) ||
          this.config.adset.destination == fbEnums.destinationType.App) &&
        !this.showMobileAppAlert
      );
    },
    disableDestination () {
      if (this.inPromoDashboard) {
        return this.isEditPromoMode
      }
      return this.isExistingConfig
    },
    isProductCatalogObjective () {
      // disable billing event dropdown'
      // if objective is 'Product Sales Catalog' and optimization goal is not 'Link Clicks'
      // OR
      // if objective is 'Lead Generation'
      return this.isLeadGenerationObjective(this.config) || (this.isDPAObjective(this.config) && this.config.adset.optimizeFor != 8);
    },
    isNomenclatureEnabled () {
      if (this.nomenclatureMacro && this.nomenclatureMacro.Campaign != '') {
        return true;
      }
      return false;
    },
    isNomenclatureEnabledForAdset () {
      if (this.nomenclatureMacro && this.nomenclatureMacro.Campaign != '' && this.nomenclatureMacro.AdSet != '') {
        return true;
      }
      return false;
    },
    supportsRoas () {
      return this.config.adset.destination == fbEnums.destinationType.Page && this.isDPAObjective(this.config, true)
    }
  },
  methods: {
    ...mapMutations(["SET_BPSEID"]),
    loadConfig (id) {
      if (id == undefined || id == "" || this.inPromoDashboard) { return; }
      this.loadingExistingConfig = true
      if (!this.isWizardFlow) {
        this.config = JSON.parse(
          this.campaignLauncherConfigs[id].config
        );
        if (this.config.isAdsetBudget) {
          this.config.budgetLevel = 2
        } else {
          this.config.budgetLevel = 1
        }
        this.config.bpseId = Number(this.config.bpseId);
        if (!this.isExistingConfig) {
          this.config.name = this.appendCopyToConfigName(this.config.name);
        }
        this.updatePlacements(true);
      }
      this.$nextTick(() => {
        this.loadingExistingConfig = false
      })
    },
    updateSchedule (updatedSchedule) {
      this.config.adset.schedule.dayParting_schedules = updatedSchedule.dayParting_schedules
      this.isWithinRange = updatedSchedule.isWithinRange
      this.selectedTimeZoneType = this.config.adset.schedule.dayParting_schedules ? this.config.adset.schedule.dayParting_schedules[0].timezone_type : 'USER';
    },
    resetScheduleType (budgetType) {
      if (budgetType == 1 && this.config.adset.schedule.type == 3) {
        this.config.adset.schedule.type = 1;
      }
    },
    setDeliveryTypeBySchedule () {
      if (this.config.adset.schedule.type === 1) {
        this.config.campaign.deliveryType = "Standard";
      } else {
        this.config.campaign.deliveryType = "day_parting";
      }
    },
    async getConfig () {
      this.setDeliveryTypeBySchedule();
      if (this.isAppInstallsObjective(this.config) && !this.config.campaign.productCatalog && this.config.campaign.isAutomatedAppAdsEnabled) {
        this.config.adset.isDynamicCreative = true;
      }
      if (this.config.adset.grouping.includes("Ad Schedule")) {
        this.config.adset.adScheduling = true
      } else {
        this.config.adset.adScheduling = false
      }
      if (this.inPromoDashboard || this.isPromotionFlow) {
        return {
          name: this.config.name,
          businessProfileSearchEngineId: this.config.bpseId,
          configJson: this.config,
          isReachAndFrequencyConfig: this.isRnFConfig,
          status: true,
          isPromotion: true
        };
      }
      let selectedFacebookAccount = this.accounts.filter(e => {
        return e.Id == this.config.bpseId
      });
      this.config.facebookAccountId = selectedFacebookAccount[0].accountId;
      let isInstagramPlacement = this.isInstagramPlacementSelected(this.config);
      if (isInstagramPlacement && !this.config.instagramAccountId) {
        let instagramId;
        let instagramIdResponse = await APIService.getPageBackedInstagramId(this.config.bpseId, this.config.facebookPageId);
        instagramId = instagramIdResponse.data.data.length > 0 ? instagramIdResponse.data.data[0].id : null;
        if (instagramId == null) {
          let createInstagramIdResponse = await APIService.createPageBackedInstagramId(this.config.bpseId, this.config.facebookPageId);
          instagramId = createInstagramIdResponse.data.data.id;
        }
        this.config.instagramAccountId = instagramId;
      } else if (!isInstagramPlacement) {
        this.config.instagramAccountId = "";
      }
      var facebookConfig = {
        bpseId: this.config.bpseId,
        name: this.config.name,
        configId: this.isExistingConfig
          ? this.loadExistingConfigId
          : -1,
        isReachAndFrequencyConfig: this.isRnFConfig,
        configJson: JSON.parse(JSON.stringify(this.config))
      };
      if (facebookConfig.configJson.adset.targetProductAudience) {
        if (typeof facebookConfig.configJson.adset.targetProductAudience != 'object') {
          facebookConfig.configJson.adset.targetProductAudience = {
            "inclusions": [],
            "exclusions": []
          }
        }
      }
      return facebookConfig;
    },
    isFacebookFormvalid (savecallback) {
      this.$refs["formCampaignConfig"].validate((valid, fields) => {
        if (valid) {
          savecallback();
        } else {
          this.$nextTick(function () {
            let errors = document.getElementsByClassName("ivu-form-item-error-tip");
            if (errors.length > 0) {
              errors[0].parentElement.scrollIntoView({ block: 'end' });
            }
          });
        }
      });
    },
    resetFields () {
      this.teardownWatchers();
      this.$refs.formCampaignConfig.resetFields();
      this.resetAlerts();
      this.$nextTick(() => {
        this.setupWatchers();
      })
    },
    isEqualArray (arr1, arr2) {
      var flag = true
      if (arr1.length != arr2.length) { return false; }
      arr1.forEach(element => {
        if (!arr2.includes(element)) { flag = false }
      });
      return flag
    },
    ProcessPlacementsByAdFormat (placementTitles, isDisabled) {
      this.config.adset.placements.forEach(ele => {
        if (placementTitles.includes(ele.title)) {
          ele.disabled = isDisabled
          ele.children.forEach(cEle => {
            if (isDisabled) {
              cEle.checked = false
            }
          })
          if (isDisabled) {
            ele.checked = false
          }
        } else {
          ele.children.forEach(cEle => {
            if (isDisabled) {
              cEle.checked = false
            }
          })
          if (isDisabled) {
            ele.checked = false
          }
          ele.disabled = !isDisabled
        }
      })
    },
    adsetSpendLimitOptionWatch (value) {
      // reset min and max values on change of option
      this.config.adset.spendLimit.min = ""
      this.config.adset.spendLimit.max = ""
    },
    setupWatchers () {
      this.unwatch.adsetSpendLimit = this.$watch("config.adset.spendLimit.option", this.adsetSpendLimitOptionWatch);
    },
    teardownWatchers () {
      this.unwatch.adsetSpendLimit();
    },
    updatePlacements (loadingFromExisting = false) {
      const selectedPlacementOption = this.config.adset.selectedPlacementOption;
      this.config.adset.selectedPlacementOption = "Automatic"

      const defaultPlacementsCopy = JSON.parse(JSON.stringify(this.defaultPlacements))
      let newPlacements = loadingFromExisting ? this.config.adset.placements : defaultPlacementsCopy;

      newPlacements.forEach(placement => {
        if (placement.title == "Facebook") {
          let fbSearchIndex = placement.children.findIndex(x => x.title == "Search")
          if (this.isTrafficObjective(this.config) || this.isConversionsObjective(this.config)) {
            if (fbSearchIndex == -1) {
              placement.children.push({ title: "Search" })
            }
          } else {
            if (fbSearchIndex != -1) {
              placement.children.splice(fbSearchIndex, 1);
            }
          }
        } else if (placement.title == "Instagram") {
          let exploreIndex = placement.children.findIndex(x => x.title == "Explore")
          if (!this.isPageLikesObjective(this.config) && !this.isEventResponsesObjective(this.config)) {
            if (exploreIndex == -1) {
              placement.children.push({ title: "Explore" })
            }
          } else {
            if (exploreIndex != -1) {
              placement.children.splice(exploreIndex, 1);
            }
          }
        }
      })
      if (loadingFromExisting) {
        this.getMobileDeviceListFromAPI(loadingFromExisting)
        this.$nextTick(() => {
          this.config.adset.placements = newPlacements
          this.config.adset.selectedPlacementOption = selectedPlacementOption;
        })
      }
    },
    fetchMobileDevices (option) {
      this.clearMobileOSData()
      if (option === "Android devices only") {
        this.setOSLevelData(this.androidDevices, fbEnums.osVersions.android, "Android")
        this.config.adset.targeting.minOsVersion = "2.0"
        this.config.adset.targeting.maxOsVersion = ""
        this.config.adset.targeting.isAndroidOs = true
      } else if (option === "iOS devices only") {
        this.setOSLevelData(this.iOSDevices, fbEnums.osVersions.ios, "iOS")
        this.config.adset.targeting.isAndroidOs = false
        this.config.adset.targeting.minOsVersion = "2.0"
        this.config.adset.targeting.maxOsVersion = ""
      }
    },
    setOSLevelData (devices, osVersion, os = null) {
      this.deviceList = devices
      this.includedDeviceList = devices
      this.excludedDeviceList = devices
      this.mobileOsVersions = osVersion
      this.minOSList = osVersion
      this.maxOSList = osVersion
      if (os) this.config.adset.targeting.userOs.push(os)
    },
    clearMobileOSData () {
      this.config.adset.targeting.userDevice = []
      this.config.adset.targeting.excludedUserDevice = []
      this.config.adset.targeting.userOs = []
      this.deviceList = []
      this.includedDeviceList = []
      this.excludedDeviceList = []
    },
    mobileDeviceParentCheckAndorid (existingList, input) {
      let companyName = input.description.split(" ")[0];
      let isTablet = /(\s+|^)tab(\s+|$)|(\s+|^)tablet(\s+|$)/i.test(input.description)
      if (isTablet && !input.description.includes("_")) {
        existingList = existingList.filter(s => !s.name.includes('_Tablet') && s.description != companyName);
      } else if (input.name.includes("_Smartphone")) {
        // This will remove all the devices if Android_Phone or Tablet is passed.
        existingList = existingList.filter(s => s.name.includes("_") || isTablet);
      } else if (input.name.includes("_Tablet")) {
        // This will remove all the devices if Android_Phone or Tablet is passed.
        existingList = existingList.filter(s => s.name.includes("_") || !isTablet);
      } else if (input.description.includes("Devices")) {
        existingList = existingList.filter(s => !s.description.includes(companyName) && !s.name.includes('_Smartphone'));
        existingList.push(input)
      } else {
        // This will handle case when user selects some device(Oneplus 6t), it would remove both companyParent( OnePlusDevices) and Android_Phone parent (All android Phones) .
        var parent = companyName + " Devices"
        existingList = existingList.filter(s => !s.name.includes('_Smartphone') && s.description != companyName && s.description != parent);
      }
      return existingList
    },
    mobileDeviceParentCheckIOS (existingList, input) {
      let productType = input.name.split(" ")[0];
      if (input.description.includes("(all)")) {
        existingList = existingList.filter(s => s.description.includes("(all)") || !s.description.includes(productType));
      } else {
        // Use regex here
        var parent = productType + "s (all)"
        existingList = existingList.filter(s => s.description.toLowerCase() != parent);
      }
      return existingList
    },
    getOSVersionString () {
      var versionString = ""
      var maxOsVersion = this.config.adset.targeting.maxOsVersion
      var minOsVersion = this.config.adset.targeting.minOsVersion
      versionString = this.config.adset.targeting.isAndroidOs ? "Android_ver_" : "iOS_ver_";
      if (maxOsVersion && maxOsVersion != "all" && minOsVersion != "none") {
        versionString += minOsVersion + "_to_" + maxOsVersion
      } else if (minOsVersion === "none" && maxOsVersion === "none") {
        versionString = this.config.adset.targeting.isAndroidOs ? "Android" : "iOS";
      } else if (minOsVersion === "none") {
        return "";
      } else {
        versionString += minOsVersion + "_and_above"
      }
      return versionString
    },
    setExistingConfigData () {
      if (this.config.adset.targeting.isAndroidOs) {
        this.setOSLevelData(this.androidDevices, fbEnums.osVersions.android)
      } else {
        this.setOSLevelData(this.iOSDevices, fbEnums.osVersions.ios)
      }
    },
    getMobileDeviceListFromAPI (loadingFromExisting) {
      APIService.get(
        "/App/CampaignLauncher/GetMobileDevices/" +
          this.businessProfileId +
          "?bpseId=" +
          this.config.bpseId
      ).then(response => {
        this.androidDevices = response.Data.AndroidDevices || "[]";
        this.iOSDevices = response.Data.IosDevices || "[]";
        if (loadingFromExisting) this.setExistingConfigData()
      });
    },
    addDpaFilter (type) {
      if (type == this.dpaFilterTypes.inclusion) {
        this.config.adset.targetProductAudience.inclusions.push({
          value: "ViewContent",
          days: 1
        });
      } else {
        this.config.adset.targetProductAudience.exclusions.push({
          value: "Purchase",
          days: 1
        });
      }
    },
    removeDpaFilter (index, type) {
      if (type == this.dpaFilterTypes.inclusion) {
        this.config.adset.targetProductAudience.inclusions.splice(index, 1);
      } else {
        this.config.adset.targetProductAudience.exclusions.splice(index, 1);
      }
    },
    getCustomConversions () {
      if (!this.config.bpseId || this.isPromotionFlow) {
        return
      }
      var requests = []
      requests.push(APIService.getCustomConversions(this.config.bpseId))
      requests.push(APIService.getCustomEvents(this.config.bpseId))
      Promise.all(requests)
        .then(responses => {
          this.customEventTypes = responses[0].data.data;
          responses[1].data.data.forEach(r => {
            r["custom_event_type"] = "OTHER";
            r["customEventStr"] = r.name;
            r["pixel"] = {};
          });
          this.customEventTypes = this.customEventTypes.concat(responses[1].data.data);
        })
        .catch(() => {
          this.customEventTypes = [];
          this.$Message.error({ background: true, content: "Error while fetching custom conversions." });
        });
    },
    fetchProductCatalogs () {
      this.areCatalogsBeingFetched = true;
      APIService.getProductCatalogs(this.businessProfileId, this.config.bpseId)
        .then(response => {
          this.areCatalogsBeingFetched = false;
          this.liveProductCatalogs = JSON.parse(response.data.liveProductCatalogs);
          this.dxProductCatalogs = JSON.parse(response.data.dxProductCatalogs);
          if (this.loadExistingConfigId == "" && this.isDPAObjective(this.config)) {
            if (this.liveProductCatalogs.length > 0) {
              this.config.campaign.productCatalog = this.liveProductCatalogs[0].Id;
            } else if (this.dxProductCatalogs.length > 0) {
              this.config.campaign.productCatalog = this.dxProductCatalogs[0].Id;
            } else {
              this.config.campaign.productCatalog = "";
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.areCatalogsBeingFetched = false;
          this.liveProductCatalogs = [];
          this.dxProductCatalogs = [];
          this.config.campaign.productCatalog = "";
          this.fetchCatalogsApiCallFailed = true;
        });
    },
    getDefaultBidStrategies () {
      return [
        { id: fbEnums.bidStrategy["Lowest Cost"], name: "Lowest Cost" },
        { id: fbEnums.bidStrategy["Lowest Cost with Bid Cap"], name: "Lowest Cost with Bid Cap" },
        { id: fbEnums.bidStrategy["Lowest Cost with Cost Cap"], name: "Lowest Cost with Cost Cap" }
      ]
    },
    loadChannelMacros () {
      if (this.nomenclatureMacro) {
        if (this.nomenclatureMacro.Campaign != '' && !this.loadingExistingConfig) {
          this.config.campaign.name = this.nomenclatureMacro.Campaign;
        } if (this.nomenclatureMacro.AdSet != '' && !this.loadingExistingConfig) {
          this.config.adset.name = this.nomenclatureMacro.AdSet;
        }
      } else if (!this.loadingExistingConfig && (!this.nomenclatureMacro || (this.nomenclatureMacro.Campaign == ''))) {
        this.config.campaign.name = "";
      }
    },
    processPlacementsForRnf () {
      if (this.config.adset.selectedPlacementOption == "Manual") {
        var validPlacementsConfig = getRnfPlacements(this.config);
        var placements = this.getValidAdsetPlacements(validPlacementsConfig);
        this.config.adset.placements = placements;
      }
    },
    conversionDomainsfilter (value, option) {
      return option.toUpperCase().indexOf(value.toUpperCase()) !== -1;
    },
    resetAlerts () {
      this.fetchCatalogsApiCallFailed = false;
      this.fetchingPageInfoFailed = false;
    }
  }
};
</script>
<style src="../../../Styles/dx-iview.css"></style>
<style>
.ccHeader {
  margin: 0px;
  font-size: 20px !important;
}
/* .ivu-form-item-content {
  padding-left: 50px;
}
.ivu-form-item-error-tip {
  margin-left: 50px;
} */
.config-modal .ivu-tabs {
  height: inherit;
}
.config-modal .ivu-tabs-content {
  height: inherit;
  padding-bottom: 50px;
}
.config-modal .ivu-tabs-tabpane {
  overflow: auto;
  height: 100%;
}
.config-modal span, .config-modal label, .config-modal input {
  font-size: 13px !important;
}
.config-modal label {
  color:#979898;
}
.config-modal .ivu-form-item {
  margin-bottom :15px !important
}
.config-modal .ivu-form-item-error-tip{
  padding-top: 0px !important;
}
.config-modal .ivu-checkbox-checked .ivu-checkbox-inner, .continue-creating .ivu-checkbox-checked .ivu-checkbox-inner{
  border-color:  var(--main-bg-color) !important;
  background-color: var(--main-bg-color) !important;
}
.config-modal .ivu-checkbox-indeterminate .ivu-checkbox-inner{
  border-color:  var(--main-bg-color) !important;
  background-color: var(--main-bg-color) !important;
}
.config-modal .ivu-radio-checked .ivu-radio-inner{
  border-color: var(--main-bg-color) !important;
}
.config-modal .ivu-radio-checked .ivu-radio-inner::after{
  background-color: var(--main-bg-color) !important;
}
.config-modal .ivu-tabs-tab-active, .config-modal .ivu-tabs-tab-focused{
  color: #2C2E2F !important;
}
.config-modal .ivu-checkbox-wrapper > .ivu-checkbox, .continue-creating > .ivu-checkbox {
  padding-right: 5px;
}
.hide-tab .ivu-tabs-bar {
  display: none;
}
.hide-tab .ivu-tabs-content {
  padding-bottom: 0px !important;
}
</style>
<style scoped>
.no-app-info-wrapper {
  margin: 0 0 20px 250px;
}
.no-app-info {
  padding: 11px 12px 12px 12px;
  height: auto;
  width: 50%;
  border-radius: 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px,
  	inset 0 -1px 0 0 #dadde1,
    inset 0 1px 0 0 #dadde1,
    inset -1px 0 0 0 #dadde1;
  border-color: rgb(218, 221, 225) #CCD0D5 #CCD0D5 #FFBA00;
  border-style: solid;
  border-width: 0px 0px 0px 4px;
}
.no-app-icon {
  font-size: 13px;
  float: left;
  color: #ffc017;
  line-height: 19px;
}
.no-app-info-msg {
  margin-left: 24px;
}
.no-app-info-bold {
  font-weight: bold;
  display: block;
  color: #6a6c6f !important;
  margin-bottom: 10px;
}
.no-app-info-text {
  color: #7a7c7e !important;
}
.no-app-info-link {
  color: #216FDB;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #ffffff;
}
::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.inclusion-exclusion-filter {
  padding: 0.25rem 0rem;
}
.iview-icons {
  cursor: pointer;
}
.add-btn {
  color: var(--main-bg-color);
}
.close-btn {
  color: maroon;
}
.primary-text-color{
  color: #ffffff !important;
}
.day-button{
  margin: 0.11rem;
}
.schedule-btn-pointer{
 cursor: pointer;
}
.add-time-slot{
  margin: 0px;
}
.add-schedule-button{
  margin-top: 1rem;
}
.schedule-block{
  padding-top:15px
}
.ivu-message-info, .ivu-alert-info{
  margin: 0px;
}
::v-deep .tooltip-inner {
    max-width: 350px !important;
    width: 350px !important;
}
</style>
