import { render, staticRenderFns } from "./TelevisionLineItem.vue?vue&type=template&id=163223d0&scoped=true&"
import script from "./TelevisionLineItem.vue?vue&type=script&lang=js&"
export * from "./TelevisionLineItem.vue?vue&type=script&lang=js&"
import style0 from "./TelevisionLineItem.vue?vue&type=style&index=0&id=163223d0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "163223d0",
  null
  
)

export default component.exports